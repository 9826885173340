import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'
import { marked } from 'marked'
import {
  secondsFromInterval,
  minutesFromInterval,
  getZoneColor,
  getZoneName,
  getStrokeString,
  formatSeconds,
  numExercises,
  dataFromReferenceDate,
  zoneList,
  strokeList
} from './Utils'
import Modal from 'react-modal'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
}

export default class CompletedWorkout extends React.Component {
  constructor (props) {
    super(props)
    if (this.props.match.params.id) {
      this.state = {
        completedWorkout: null,
        activeLap: null,
        activeSet: null,
        modalIsOpen: false,
        activeLabIndex: '',
        setIsOpen: false,
        activeSetGroupIndex: null,
        intervalMin: 0,
        intervalMs: 0,
        splits: []
      }
      this.loadAllExerciseTypes()
      this.loadWorkout()
      this.getCurrentAnalysis()
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.completedWorkout != this.state.completedWorkout) {
      if (
        this.state.completedWorkout &&
        this.state.completedWorkout.get('heartRateData') &&
        this.state.completedWorkout.get('heartRateData').length > 0
      ) {
        const self = this

        const ctx = document.getElementById('chart').getContext('2d')
        const myChart = new Chart(ctx, {
          type: 'line',
          data: {
            labels: self.state.completedWorkout
              .get('heartRateData')
              .map((item) => item.value),
            datasets: [
              {
                label: 'Heart Rate Graph',
                fill: 'false',
                borderWidth: 1,
                pointBorderWidth: 0.5,
                borderColor: 'red',
                backgroundColor: 'transparent',
                data: self.state.completedWorkout
                  .get('heartRateData')
                  .map((item) => item.value)
              }
            ]
          },
          options: {
            yAxes: [
              {
                position: 'left',
                id: 'y-axis-0'
              },
              {
                position: 'right',
                id: 'y-axis-1'
              }
            ],
            plugins: {
              legend: false,
              tooltip: true
            },
            scales: {
              xAxes: [
                {
                  display: false // this will remove all the x-axis grid lines
                }
              ]
            }
          }
        })
      }
    }
  }

  loadWorkout = () => {
    const id = this.props.id || this.props.match.params.id
    if (!id) {
      alert(
        'Error: No member id. URL format: https://admin.myswimpro.com/#/completedworkout/id'
      )
      return
    }

    const completedWorkoutType = new Parse.Object('CompletedWorkout_v3')
    const query = new Parse.Query(completedWorkoutType)
    query.include(['workout.setGroups.sets', 'image'])
    query.get(id).then(
      (results) => {
        if (!results) {
          alert('No workout found for id ' + id)
          return
        }
        this.setState({
          completedWorkout: results
        })
      },
      (error) => {
        alert('Error getting member: ' + error.message)
      }
    )
  }

  loadAllExerciseTypes = () => {
    const exerciseType = new Parse.Object('DrylandExercise')
    const query = new Parse.Query(exerciseType)
    query.limit(1000)
    query.find().then(
      (results) => {
        console.log(results.length + ' existing exercises.')
        this.setState({
          exerciseTypes: results
        })
      },
      (error) => {
        alert(error)
      }
    )
  }

  handleDisqualify = async (e) => {
    e.preventDefault()
    const completedWorkout = this.state.completedWorkout
    const isCurrentlyDisqualified = !!completedWorkout.get(
      'isDisqualifiedFromChallenges'
    )
    completedWorkout.set(
      'isDisqualifiedFromChallenges',
      !isCurrentlyDisqualified
    )
    await completedWorkout.save()
    this.setState({
      completedWorkout: completedWorkout
    })
  }

  getExerciseString = (value) => {
    if (value === undefined || !this.state.exerciseTypes) {
      return 'undefined'
    }
    if (!this.state.exerciseTypes[value]) {
      return 'undefined'
    }
    return this.state.exerciseTypes[value].get('name')
  }

  getPoolLengthString = (poolLength, poolCourse) => {
    if (poolLength === 0) {
      return 'Open Water'
    } else if (poolLength === undefined) {
      return 'Undefined'
    } else {
      return `${poolLength} ${poolCourse === 0 ? 'yards' : 'meters'}`
    }
  }

  getPoolString = (pool) => {
    if (!pool) return
    if (pool.isDisplayInYards) {
      const convertedDistanceRaw = pool.distanceInMeters * 1.09361
      const convertedDistanceRoundTo2decimals = Math.round(convertedDistanceRaw * 100) / 100
      return `${convertedDistanceRoundTo2decimals}yd`
    } else {
      return `${pool.distanceInMeters}m`
    }
  }

  handleEditUser = (event) => {
    event.preventDefault()

    const newId = window.prompt('Please enter the new userId')
    if (!newId || newId === '') return

    const user = new Parse.User()
    user.id = newId

    this.state.completedWorkout.set('user', user)
    this.state.completedWorkout.save().then(
      () => {
        this.setState({
          completedWorkout: this.state.completedWorkout
        })
      },
      (error) => {
        window.alert('Error saving: ' + error.message)
      }
    )
  }

  handleEditDistance = (event) => {
    event.preventDefault()

    const newDistance = window.prompt('Please enter the new distance')
    if (!newDistance || newDistance === '') return

    const dist = parseInt(newDistance)

    this.state.completedWorkout.set('totalDistance', dist)
    this.state.completedWorkout.save().then(
      () => {
        this.setState({
          completedWorkout: this.state.completedWorkout
        })
      },
      (error) => {
        window.alert('Error saving: ' + error.message)
      }
    )
  }

  handleGenerateFitFile = (event) => {
    event.preventDefault()
    const params = { completedWorkoutId: this.state.completedWorkout.id }
    Parse.Cloud.run('generateFitFile', params).then(
      (results) => {
        console.log(results)
        window.location.href = results.url
      },
      (error) => {
        window.alert('Error generating fit file: ' + error.message)
      }
    )
  }

  renderWorkoutStructure = (completedWorkout) => {
    const setGroups = completedWorkout.get('workout').get('setGroups') || []

    return setGroups.map(this.renderSetGroup)
  }

  renderLaps = (completedWorkout) => {
    const lapsList = completedWorkout.get('lapSamplesData') || []
    return lapsList.map(this.renderLapsList)
  }

  onSetChange = (event) => {
    event.preventDefault()

    const set = this.state.activeSet
    set.set(event.target.name, parseInt(event.target.value))
    this.setState({
      activeSet: set
    })
  }

  onSelectChange = (name, event) => {
    event.preventDefault()
    const set = this.state.activeSet
    set.set(name, parseInt(event.target.value))
    this.setState({
      activeSet: set
    })
  }

  renderLapsList = (lab, index) => {
    return (
      <div>
        <div style={{ marginLeft: 20 + 'px' }}>
          <small>
            <b>Lap {index + 1}:</b> {getStrokeString(lab.strokeType)}{' '}
          </small>{' '}
          <small
            style={{ color: 'red' }}
            onClick={() => {
              this.setState({
                modalIsOpen: true,
                activeLap: lab,
                activeLabIndex: index
              })
            }}
          >
            Edit
          </small>
          <br />
          <small>
            {lab.totalSwimTime?.toFixed(2)}s | {lab.strokeCount}({lab.strokeCountRaw}) Strokes | {lab.lapLength}{lab.lapDistanceUnit === 0 ? 'm' : 'yd'}
          </small>
        </div>
        <br />
      </div>
    )
  }

  renderSetGroup = (setGroup, index) => {
    const sets = setGroup.get('sets') || []
    return (
      <div>
        <h4>
          {setGroup.get('title')}{' '}
          <small className="text-muted">{setGroup.get('reps')}x:</small>
        </h4>
        <div style={{ marginLeft: 20 + 'px' }}>
          {sets.map((set, j) => this.renderSet(set, j, index))}
        </div>
        <br />
      </div>
    )
  }

  renderSet = (set, index, groupIndex) => {
    console.log('set', set.get('interval'))
    if (
      index === this.state.activeSetIndex &&
      this.state.activeSetGroupIndex === groupIndex
    ) {
      const { activeSet } = this.state
      return (
        <div>
          <b>
            <input
              name="reps"
              value={activeSet.get('reps')}
              onChange={this.onSetChange}
              style={{ width: 50 }}
            />{' '}
            x{' '}
            <input
              name="distance"
              value={activeSet.get('distance')}
              onChange={this.onSetChange}
              style={{ width: 50 }}
            />{' '}
            {getStrokeString(set.get('stroke'))}
            <input
              className="ml-2"
              name="interval"
              value={this.state.intervalMin}
              onChange={(event) => {
                this.setState({
                  intervalMin: event.target.value
                })
              }}
              style={{ width: 50 }}
            />{' '}
            :{' '}
            <input
              name="interval"
              className="ml-2"
              value={this.state.intervalMs}
              onChange={(event) => {
                this.setState({
                  intervalMs: event.target.value
                })
              }}
              style={{ width: 50 }}
            />
          </b>
          &nbsp;
          <div
            style={{
              paddingLeft: 2 + 'px',
              paddingRight: 2 + 'px',
              backgroundColor: getZoneColor(set.get('zone')),
              display: 'inline-block'
            }}
          >
            {' '}
            <select
              className="custom-select"
              value={activeSet.get('zone')}
              name="zone"
              onChange={(value) => this.onSelectChange('zone', value)}
            >
              {zoneList.map((item) => {
                return <option value={item.value} key={item.value}>{item.label}</option>
              })}
            </select>
          </div>
          <small
            className="btn btn-success btn-sm ml-2"
            onClick={this.updateWorkoutSet}
          >
            Save
          </small>
          <small
            className="btn btn-default btn-sm ml-2"
            onClick={this.clearTemp}
          >
            <span style={{ color: 'red' }}>Cancel</span>
          </small>
          <br />
          <div style={{ marginLeft: 20 + 'px' }} className="text-muted">
            {activeSet && activeSet.get('recordedSwimTimes') && activeSet.get('recordedSwimTimes').length > 0 && (<small>Splits</small>)}

            {(activeSet.get('recordedSwimTimes') || []).map((split, k) => {
              if (!split) return '<></>'
              return (
                <div
                  className="row"
                  key={k}>
                  <input
                    name="interval"
                    className="mr-2"
                    value={this.state.splits[k]?.min}
                    onChange={(event) => {
                      const updates = this.state.splits
                      updates[k].min = event.target.value
                      this.setState({
                        splits: updates
                      })
                    }}
                    style={{ width: 50 }}
                  />
                  {'  '}:{' '}
                  <input
                    name="interval"
                    className="ml-2"
                    value={this.state.splits[k]?.ms}
                    onChange={(event) => {
                      const updates = this.state.splits
                      updates[k].ms = event.target.value
                      this.setState({
                        splits: updates
                      })
                    }}
                    style={{ width: 50 }}
                  />
                </div>
              )
            })}
          </div>
        </div>
      )
    } else if (!set.get('setType')) {
      // Swim set
      return (
        <div>
          <b>
            {set.get('reps')} x {set.get('distance')}{' '}
            {getStrokeString(set.get('stroke'))}{' '}
            {this.renderInterval(set.get('interval'))}{' '}
          </b>
          &nbsp;
          <div
            style={{
              paddingLeft: 2 + 'px',
              paddingRight: 2 + 'px',
              backgroundColor: getZoneColor(set.get('zone')),
              display: 'inline-block'
            }}
          >
            {' '}
            {getZoneName(set.get('zone'))}{' '}
          </div>
          <small
            className="ml-2"
            style={{ color: 'red' }}
            onClick={() => {
              const reps = set.get('recordedSwimTimes')
              const arr = []
              reps &&
                reps.length > 0 &&
                reps.forEach((element) => {
                  const obj = {}
                  obj.min = parseInt(
                    (element - (Math.round(element) % 60)) / 60
                  )
                  obj.ms = Math.round(element) % 60
                  arr.push(obj)
                })
              this.setState({
                activeSet: set,
                activeSetIndex: index,
                activeSetGroupIndex: groupIndex,
                splits: arr,
                intervalMs: set.get('interval') % 60,
                intervalMin:
                  (set.get('interval') - Math.round(set.get('interval') % 60)) /
                  60
              })
            }}
          >
            Edit
          </small>
          <br />
          <div style={{ marginLeft: 20 + 'px' }}>
            Goal Times:{' '}
            <span className="text-muted">
              [
              {(set.get('goalTimes') || [])
                .map((split) => {
                  if (!split) return 'ø'
                  return formatSeconds(split)
                })
                .join(', ')}
              ]
            </span>
          </div>
          <div style={{ marginLeft: 20 + 'px' }}>
            Recorded Splits:{' '}
            <span className="text-muted">
              [
              {(set.get('recordedSwimTimes') || [])
                .map((split) => {
                  if (!split) return 'ø'
                  return formatSeconds(split)
                })
                .join(', ')}
              ]
            </span>
          </div>
        </div>
      )
    } else {
      // Dryland set
      return (
        <div>
          {set.get('reps')} x {this.getExerciseString(set.get('exerciseType'))}{' '}
          {this.renderInterval(set.get('interval'))}
          <br />
          <div style={{ marginLeft: 20 + 'px' }} className="text-muted">
            {(set.get('recordedSwimTimes') || [])
              .map((split) => {
                if (!split) return 'ø'
                return formatSeconds(split)
              })
              .join(', ')}
          </div>
        </div>
      )
    }
  }

  renderInterval = (interval) => {
    if (!interval) return ''
    return '@ ' + formatSeconds(interval)
  }

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      activeLap: null,
      activeSet: null,
      activeLabIndex: null,
      activeSetIndex: null
    })
  }

  setLab = async (e) => {
    e.preventDefault()

    const completedWorkout = this.state.completedWorkout
    const lapsList = completedWorkout.get('lapSamplesData')
    lapsList[this.state.activeLabIndex] = this.state.activeLab

    completedWorkout.set('lapSamplesData', lapsList)
    await completedWorkout.save()
    this.setState({
      completedWorkout: completedWorkout,
      modalIsOpen: false
    })
  }

  updateWorkoutSet = async (e) => {
    e.preventDefault()

    const completedWorkout = this.state.completedWorkout
    const workout = completedWorkout.get('workout')
    const setGroups = completedWorkout.get('workout').get('setGroups')

    this.state.activeSet.set(
      'interval',
      parseInt(
        parseFloat(this.state.intervalMin * 60) +
        parseInt(this.state.intervalMs)
      )
    )

    const arr = []
    this.state.splits.forEach((element) => {
      arr.push(parseFloat(parseInt(element.min * 60) + parseInt(element.ms)))
    })

    this.state.activeSet.set('recordedSwimTimes', arr)

    const collectionOfSets =
      setGroups[this.state.activeSetGroupIndex].get('sets')
    collectionOfSets[this.state.activeSetIndex] = this.state.activeSet
    setGroups[this.state.activeSetGroupIndex].set('sets', collectionOfSets)

    workout.set('setGroups', setGroups)
    completedWorkout.set('workout', workout)
    await completedWorkout.save()
    this.setState({
      completedWorkout: completedWorkout,
      modalIsOpen: false,
      activeSet: null,
      activeSetIndex: null,
      intervalMin: 0,
      intervalMs: 0,
      splits: []
    })
  }

  clearTemp = () => {
    this.setState({
      modalIsOpen: false,
      activeSet: null,
      activeSetIndex: null,
      intervalMin: 0,
      intervalMs: 0,
      splits: []
    })
  }

  generateAnalysis = async (e) => {
    e.preventDefault()
    const aiPrompt = window.prompt('Enter a prompt for AI Swim Coach. (optional)\n\nExamples:\n"What was my fastest split?"\n"What should I work on for next time?"\n"What was my average stroke count during the 100s free?"\n"What are the top three takeaways from this swim?"')
    if (aiPrompt === null) return
    this.setState({
      isGeneratingAnalysis: true,
      userPrompt: aiPrompt
    })
    const completedWorkout = this.state.completedWorkout
    const params = {
      completedWorkoutId: completedWorkout.id,
      prompt: aiPrompt
    }
    try {
      const result = await Parse.Cloud.run('getAIWorkoutAnalysis', params)
      this.setState({
        completedWorkout: completedWorkout,
        isGeneratingAnalysis: false,
        analysisText: result.response,
        systemPrompt: result.systemPrompt,
        userPrompt: result.userPrompt
      })
    } catch (err) {
      alert('Error generating analysis: ' + err.message)
      this.setState({
        isGeneratingAnalysis: false
      })
    }
  }

  getCurrentAnalysis = async () => {
    const id = this.props.id || this.props.match.params.id
    const params = {
      completedWorkoutId: id
    }
    try {
      const result = await Parse.Cloud.run('getWorkoutCoachFeedback', params)
      this.setState({
        analysisText: result.get('text'),
        userPrompt: result.get('userPrompt'),
        systemPrompt: result.get('systemPrompt')
      })
    } catch (err) {
      alert('Error generating analysis: ' + err.message)
    }
  }

  onChange = (event) => {
    const updates = this.state.activeLap
    updates[event.target.name] = event.target.value
    this.setState({
      activeLab: updates
    })
  }

  render = () => {
    if (!this.state.completedWorkout) return <div>Loading...</div>

    console.log('hrdata', this.state.completedWorkout.get('recordedSwimTimes'))
    console.log(zoneList)
    return (
      <div>
        <br />
        <br />
        <div className="container">
          <h1>
            {this.state.completedWorkout.get('title')}{' '}
            <small className="text-muted">
              {this.state.completedWorkout.id}
            </small>
          </h1>
          <br />
          <div className="row">
            <div className="col-sm-4">
              <div className="card">
                <div className="card-body">
                  <b>Distance</b>&nbsp;
                  <small>
                    <a href="" onClick={this.handleEditDistance}>
                      Edit
                    </a>
                  </small>
                  <p className="text-muted">
                    {this.state.completedWorkout.get('totalDistance')}{' '}
                    {this.state.completedWorkout.get('workout').get('pool')?.isDisplayInYards || this.state.completedWorkout.get('poolCourse') === 0
                      ? 'yards'
                      : 'meters'}
                  </p>
                  <b>Duration</b>
                  <p className="text-muted">
                    {parseInt(
                      this.state.completedWorkout.get('totalTime') / 60,
                      10
                    )}{' '}
                    min
                  </p>
                  <b>Calories</b>
                  <p className="text-muted">
                    {this.state.completedWorkout.get('calories')}
                  </p>
                  <b>Pool</b>
                  <p className="text-muted">
                    {this.getPoolString(this.state.completedWorkout.get('workout').get('pool')) || this.getPoolLengthString(this.state.completedWorkout.get('workout').get('poolLength'), this.state.completedWorkout.get('poolCourse'))}
                  </p>
                  <b>Avg Freestyle SWOLF v3</b>
                  <p className="text-muted">
                    {this.state.completedWorkout.get('swolfFreestyleAverage_v3')}
                  </p>
                  <b>Avg Freestyle SWOLF v4</b>
                  <p className="text-muted">
                    {this.state.completedWorkout.get('swolfFreestyleAverage_v4')}
                  </p>
                  <b>Avg Freestyle SWOLF v5</b>
                  <p className="text-muted">
                    {this.state.completedWorkout.get('swolfFreestyleAverage_v5')}
                  </p>
                  <b>Completed at</b>
                  <p className="text-muted">
                    {this.state.completedWorkout
                      .get('dateCompleted')
                      .toString()}
                  </p>
                  <b>Seconds from GMT</b>
                  <p className="text-muted">
                    {this.state.completedWorkout.get('secondsFromGMT')
                      ? this.state.completedWorkout
                        .get('secondsFromGMT')
                        .toString()
                      : 'n/a'}
                  </p>
                  <b>GPS data</b>
                  <p className="text-muted">
                    {this.state.completedWorkout.get('locationSamples')
                      ? this.state.completedWorkout.get('locationSamples')
                        .length + ' samples'
                      : 'n/a'}
                  </p>
                </div>
              </div>
              <br />
              <div className="card">
                <div className="card-body">
                  <b>HR data</b>
                  <p className="text-muted">
                    {this.state.completedWorkout.get('heartRateData')
                      ? this.state.completedWorkout.get('heartRateData')
                        .length + ' samples'
                      : 'n/a'}
                  </p>
                  {this.state.completedWorkout.get('heartRateData') &&
                    this.state.completedWorkout.get('heartRateData').length > 0 && (
                      <div>
                        <canvas id="chart" width="200" height="200"></canvas>
                      </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="card">
                <div className="card-body">
                  <b>Logged by</b>{' '}
                  <small>
                    <a href="" onClick={this.handleEditUser}>
                      Edit
                    </a>
                  </small>
                  <p className="text-muted">
                    <a
                      href={
                        '/#/members/' +
                        this.state.completedWorkout.get('user').id
                      }
                    >
                      {this.state.completedWorkout.get('user').id}
                    </a>
                  </p>
                  <b>Created at</b>
                  <p className="text-muted">
                    {this.state.completedWorkout.get('createdAt').toString()}
                  </p>
                  <b>SyncUUID</b>
                  <p className="text-muted">
                    {this.state.completedWorkout.get('syncUUID')}
                  </p>
                  <b>AI Model</b>
                  <p className="text-muted">
                    {this.state.completedWorkout.get('workout').get('aiModel')}
                  </p>
                  <b>Universal Link</b>
                  <p className="text-muted">
                    <a
                      href={
                        'https://myswimpro.com/applink/?feature=social&completedWorkoutId=' +
                        this.state.completedWorkout.id
                      }
                    >
                      {'https://myswimpro.com/applink/?feature=social&completedWorkoutId=' +
                        this.state.completedWorkout.id}
                    </a>
                  </p>
                </div>
              </div>
              <br />
              <div className="card">
                <div className="card-body">
                  <b>Start date</b>
                  <p className="text-muted">
                    {this.state.completedWorkout?.get('workout')?.get('recordedStartDate')?.toString()}
                  </p>
                  <b>End date</b>
                  <p className="text-muted">
                    {this.state.completedWorkout?.get('workout')?.get('recordedEndDate')?.toString()}
                  </p>
                </div>
              </div>
              <br />
              <div className="card">
                <div className="card-body">
                  <b>Challenge Eligibility</b>{' '}
                  <small>
                    <a href="#" onClick={this.handleDisqualify}>
                      Flip
                    </a>
                  </small>
                  <p className="text-muted">
                    {this.state.completedWorkout.get(
                      'isDisqualifiedFromChallenges'
                    )
                      ? 'Ineligible'
                      : 'Eligible'}
                  </p>
                </div>
              </div>
              <br />
              <div className="card">
                <div className="card-body">
                  <b>Lap Samples</b>{' '}
                  <small>
                    <a href="#lapCollapse" data-toggle="collapse" >
                      Show/Hide
                    </a>
                  </small>
                  <p className="text-muted">
                    {this.state.completedWorkout.get('lapSamplesData')?.length || 0} samples
                  </p>
                  <div className="collapse" id="lapCollapse">
                    {this.renderLaps(this.state.completedWorkout)}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="card">
                <div className="card-body">
                  <b>Model Name</b>
                  <p className="text-muted">
                    {this.state.completedWorkout.get('modelName')}
                  </p>
                  <b>Device Version</b>
                  <p className="text-muted">
                    {this.state.completedWorkout.get('deviceVersion')}
                  </p>
                  <b>App Version</b>
                  <p className="text-muted">
                    {this.state.completedWorkout.get('appVersion')}
                  </p>
                  <b>Workout Source</b>
                  <p className="text-muted">
                    {this.state.completedWorkout.get('workoutSource')}
                  </p>
                  <b>Runner Platform</b>
                  <p className="text-muted">
                    {this.state.completedWorkout.get('workoutRunnerPlatform')}
                  </p>
                  <b>Garmin Connect Workout</b>
                  <p className="text-muted">
                    {this.state.completedWorkout.get('garminConnectWorkoutId') || 'n/a'}
                  </p>
                  <b>Guided</b>
                  <p className="text-muted">
                    {this.state.completedWorkout.get('isGuided')
                      ? 'true'
                      : 'false'}
                  </p>
                  <b>Test Set</b>
                  <p className="text-muted">
                    {this.state.completedWorkout.get('workout').get('isTestSetWorkout')
                      ? 'true'
                      : 'false'}
                  </p>
                  <b>Generated</b>
                  <p className="text-muted">
                    {this.state.completedWorkout.get('isGenerated')
                      ? 'true'
                      : 'false'}
                  </p>
                  <b>Splash Mode</b>
                  <p className="text-muted">
                    {this.state.completedWorkout.get('isSplashModeRunner')
                      ? 'true'
                      : 'false'}
                  </p>
                </div>
              </div>
              <br />
              <div className="card">
                <div className="card-body">
                  <b>Image</b>
                  <br/>
                  {this.state.completedWorkout.get('image')
                    ? (
                    <div>
                      <a href="#imageCollapse" className="btn btn-sm btn-secondary" data-toggle="collapse" >
                        Show/Hide
                      </a>
                      <br /><br />
                      <div className="collapse" id="imageCollapse">
                        <img className="img-fluid" src={this.state.completedWorkout.get('image').url()} />
                      </div>
                    </div>
                      )
                    : (
                  <p>None</p>
                      )}
                </div>
              </div>
              <br />
              <div className="card">
                <div className="card-body">
                  <b>Input .fit file</b>
                  <p className="text-muted">
                    {this.state.completedWorkout.get('sourceFITFile')
                      ? (
                        <a
                          href={this.state.completedWorkout
                            .get('sourceFITFile')
                            .url()}
                          download
                        >
                          <button className="btn btn-sm btn-secondary">
                            Download
                          </button>
                        </a>
                        )
                      : (
                        <span>None</span>
                        )}
                  </p>
                  <b>Output .fit file</b>
                  <p className="text-muted">
                    {this.state.completedWorkout.get('createdFITFile')
                      ? (
                        <a
                          href={this.state.completedWorkout
                            .get('createdFITFile')
                            .url()}
                          download
                        >
                          <button className="btn btn-sm btn-secondary">
                            Download
                          </button>
                        </a>
                        )
                      : (
                        <span>None</span>
                        )}
                    &nbsp;
                    <button
                      className="btn btn-sm btn-outline-secondary"
                      onClick={this.handleGenerateFitFile}
                    >
                      Generate fit file
                    </button>
                  </p>
                  <b>Strava Activity Id</b>
                  <p className="text-muted">
                    <a
                      href={
                        'https://strava.com/activities/' +
                        this.state.completedWorkout.get('stravaActivityId')
                      }
                    >
                      {this.state.completedWorkout.get('stravaActivityId') ||
                        'None'}
                    </a>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-sm-4"></div>
          </div>

          <br />
          <hr />
          <br />
          <div className="row">
            <div className="col-sm-6">
              {this.renderWorkoutStructure(this.state.completedWorkout)}
            </div>

            <div className="col-sm-6">
              <div className="card">
                <div className="card-body">
                  <b>AI Swim Coach Analysis</b>{' '}
                  <small>
                    <a href="#" onClick={this.generateAnalysis} hidden={this.state.isGeneratingAnalysis}>
                      Generate
                    </a>
                  </small>
                  <br /><br />
                  <a href="#promptCollapse" className="btn btn-sm btn-secondary" data-toggle="collapse" >
                    Show/Hide Prompts
                  </a>
                  <br /><br />
                  <div className="collapse" id="promptCollapse">
                    <p hidden={!this.state.systemPrompt} className="text-muted">
                      <b>System:</b>
                      {this.state.systemPrompt}
                    </p>
                    <p hidden={!this.state.userPrompt} className="text-muted">
                      <b>User:</b>
                      {this.state.userPrompt}
                    </p>
                  </div>
                  <p hidden={!this.state.isGeneratingAnalysis} className="text-muted">Loading...</p>
                  <p hidden={this.state.isGeneratingAnalysis} dangerouslySetInnerHTML={{ __html: marked.parse(this.state.analysisText || '') }}></p>
                </div>
              </div>
            </div>
          </div>

          {this.state.activeLap && (
            <Modal
              isOpen={this.state.modalIsOpen}
              onRequestClose={this.closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <form onSubmit={this.setLab}>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Stroke Type </label>

                  <select
                    className="custom-select"
                    value={this.state.activeLap.strokeType}
                    name="strokeType"
                    onChange={(event) => {
                      const updates = this.state.activeLap
                      updates.strokeType = event.target.value
                      this.setState({
                        activeLab: updates
                      })
                    }}
                  >
                    {strokeList.map((item) => {
                      return <option value={item.value} key={item.value}>{item.label}</option>
                    })}
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">totalSwimTime </label>
                  <input
                    type="text"
                    className="form-control"
                    name="totalSwimTime"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    onChange={this.onChange}
                    value={this.state.activeLap.totalSwimTime}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">strokeCount </label>
                  <input
                    type="text"
                    className="form-control"
                    name="strokeCount"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    onChange={this.onChange}
                    value={this.state.activeLap.strokeCount}
                  />
                </div>
                <button type="submit" className="btn btn-primary">
                  Save & Update
                </button>
              </form>
            </Modal>
          )}
        </div>
      </div>
    )
  }
}
