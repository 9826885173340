import React from 'react'
import Parse from 'parse'
import 'bootstrap/dist/css/bootstrap.min.css'
import { formatSeconds, getSkillLevelString } from '../Utils'

const pageSize = 10000
const maxCSVSize = 100000

export default class Emails extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      infoMap: {},
      isLoading: false,
      skip: 0,
      premiumFilter: 0,
      isStartDateEnabled: true,
      isEndDateEnabled: true
    }
  }

  convertToCSV = (dict) => {
    const header = 'firstName, lastName, email, locale, skillLevel, workoutsLogged, planId, trialStartDate, trialEndDate, periodStartDate, periodExpirationDate, syncStrava, syncGarmin, eliteOverride, subStatus, upgraded, isAdTrackingDisabled\n'
    const data = Object.keys(dict).map((k) => {
      const user = dict[k]
      return (user.firstName === undefined || user.firstName === '' ? '' : user.firstName) +
            ',' + (user.lastName === undefined || user.lastName === '' ? '' : user.lastName) +
            ',' + user.email +
            ',' + user.locale +
            ',' + user.skillLevel +
            ',' + user.totalWorkoutsCompleted +
            ',' + user.planId +
            ',' + user.trialStartDate +
            ',' + user.trialEndDate +
            ',' + user.periodStartDate +
            ',' + user.periodExpirationDate +
            ',' + user.stravaLinked +
            ',' + user.garminLinked +
            ',' + user.eliteOverride +
            ',' + user.subStatus +
            ',' + user.upgraded +
            ',' + user.isAdTrackingDisabled
    }).join('\n')
    return header + data
  }

  downloadFile = (suffix) => {
    console.log('downloading file')
    if (suffix) {
      suffix = '_' + suffix
    } else {
      suffix = ''
    }
    const element = document.createElement('a')
    const file = new Blob([this.convertToCSV(this.state.infoMap)], { type: 'text/csv' })
    element.href = URL.createObjectURL(file)
    element.download = 'userEmails' + this.state.startDate.toISOString() + suffix + '.csv'
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
  }

  baseQuery = (skip, pageSize) => {
    const userQuery = new Parse.Query(Parse.User)
    userQuery.limit(pageSize)
    userQuery.skip(skip)
    userQuery.withCount(skip === 0)
    if (this.state.createdAfterDate && this.state.isStartDateEnabled) {
      userQuery.greaterThanOrEqualTo('createdAt', this.state.createdAfterDate)
    }
    if (this.state.createdBeforeDate && this.state.isEndDateEnabled) {
      userQuery.lessThanOrEqualTo('createdAt', this.state.createdBeforeDate)
    }
    if (this.state.isAdTrackingDisabledFilterOn) {
      userQuery.notEqualTo('isAdTrackingDisabled', true)
    }

    userQuery.include(['subscription', 'subscription_v4', 'userInfo_v3'])
    userQuery.exists('email')
    return userQuery
  }

  eliteCoachQuery = (userQuery, skip, pageSize) => {
    const now = new Date()

    // planId includes com_myswimpro_elite_coach or
    const subscriptionV4Query = new Parse.Query(Parse.Object('Subscription_v4'))
    subscriptionV4Query.greaterThanOrEqualTo('periodExpirationDate', now)
    subscriptionV4Query.doesNotExist('planCancelDate')
    subscriptionV4Query.startsWith('planId', 'com_myswimpro_elite_coach_')
    userQuery.matchesQuery('subscription_v4', subscriptionV4Query)
    userQuery.exists('subscription_v4')

    const userQuery2 = this.baseQuery(skip, pageSize)
    const subscriptionV4Query1 = new Parse.Query(Parse.Object('Subscription_v4'))
    subscriptionV4Query1.greaterThanOrEqualTo('periodExpirationDate', now)
    subscriptionV4Query1.greaterThanOrEqualTo('planCancelDate', now)
    subscriptionV4Query1.startsWith('planId', 'com_myswimpro_elite_coach_')
    userQuery2.matchesQuery('subscription_v4', subscriptionV4Query1)
    userQuery2.exists('subscription_v4')

    const orQuery = Parse.Query.or(userQuery, userQuery2)
    orQuery.withCount(true)
    orQuery.limit(pageSize)
    orQuery.skip(skip)
    return orQuery
  }

  drylandPackQuery = (userQuery, skip, pageSize) => {
    const now = new Date()

    // planId includes com_myswimpro_elite_coach or
    const subscriptionV4Query = new Parse.Query(Parse.Object('Subscription_v4'))
    subscriptionV4Query.greaterThanOrEqualTo('periodExpirationDate', now)
    subscriptionV4Query.doesNotExist('planCancelDate')
    subscriptionV4Query.startsWith('planId', 'com_myswimpro_elite_dryland_')
    userQuery.matchesQuery('subscription_v4', subscriptionV4Query)
    userQuery.exists('subscription_v4')

    const userQuery2 = this.baseQuery(skip, pageSize)
    const subscriptionV4Query1 = new Parse.Query(Parse.Object('Subscription_v4'))
    subscriptionV4Query1.greaterThanOrEqualTo('periodExpirationDate', now)
    subscriptionV4Query1.greaterThanOrEqualTo('planCancelDate', now)
    subscriptionV4Query1.startsWith('planId', 'com_myswimpro_elite_dryland_')
    userQuery2.matchesQuery('subscription_v4', subscriptionV4Query1)
    userQuery2.exists('subscription_v4')

    const orQuery = Parse.Query.or(userQuery, userQuery2)
    orQuery.withCount(true)
    orQuery.limit(pageSize)
    orQuery.skip(skip)
    return orQuery
  }

  swimmingPackQuery = (userQuery, skip, pageSize) => {
    const now = new Date()

    // planId includes com_myswimpro_elite_coach or
    const subscriptionV4Query = new Parse.Query(Parse.Object('Subscription_v4'))
    subscriptionV4Query.greaterThanOrEqualTo('periodExpirationDate', now)
    subscriptionV4Query.doesNotExist('planCancelDate')
    subscriptionV4Query.startsWith('planId', 'com_myswimpro_elite_swim_')
    userQuery.matchesQuery('subscription_v4', subscriptionV4Query)
    userQuery.exists('subscription_v4')

    const userQuery2 = this.baseQuery(skip, pageSize)
    const subscriptionV4Query1 = new Parse.Query(Parse.Object('Subscription_v4'))
    subscriptionV4Query1.greaterThanOrEqualTo('periodExpirationDate', now)
    subscriptionV4Query1.greaterThanOrEqualTo('planCancelDate', now)
    subscriptionV4Query1.startsWith('planId', 'com_myswimpro_elite_swim_')
    userQuery2.matchesQuery('subscription_v4', subscriptionV4Query1)
    userQuery2.exists('subscription_v4')

    const orQuery = Parse.Query.or(userQuery, userQuery2)
    orQuery.withCount(true)
    orQuery.limit(pageSize)
    orQuery.skip(skip)
    return orQuery
  }

  subscriberQuery = (userQuery, skip, pageSize) => {
    const now = new Date()

    const subscriptionQuery = new Parse.Query(Parse.Object('Subscription'))
    subscriptionQuery.equalTo('level', 1)
    subscriptionQuery.greaterThanOrEqualTo('expirationDate', now)
    userQuery.matchesQuery('subscription', subscriptionQuery)
    userQuery.exists('subscription')

    const userQuery2 = this.baseQuery(skip, pageSize)
    const subscriptionV4Query1 = new Parse.Query(Parse.Object('Subscription_v4'))
    subscriptionV4Query1.greaterThanOrEqualTo('periodExpirationDate', now)
    subscriptionV4Query1.doesNotExist('planCancelDate')
    userQuery2.matchesQuery('subscription_v4', subscriptionV4Query1)
    userQuery2.exists('subscription_v4')

    const userQuery3 = this.baseQuery(skip, pageSize)
    const subscriptionV4Query2 = new Parse.Query(Parse.Object('Subscription_v4'))
    subscriptionV4Query2.greaterThanOrEqualTo('periodExpirationDate', now)
    subscriptionV4Query2.greaterThanOrEqualTo('planCancelDate', now)
    userQuery3.matchesQuery('subscription_v4', subscriptionV4Query2)
    userQuery3.exists('subscription_v4')

    const userQuery4 = this.baseQuery(skip, pageSize)
    const subscriptionV4Query3 = new Parse.Query(Parse.Object('Subscription_v4'))
    subscriptionV4Query3.greaterThanOrEqualTo('trialEndDate', now)
    subscriptionV4Query3.doesNotExist('planCancelDate')
    userQuery4.matchesQuery('subscription_v4', subscriptionV4Query3)
    userQuery4.exists('subscription_v4')

    const userQuery5 = this.baseQuery(skip, pageSize)
    const subscriptionV4Query4 = new Parse.Query(Parse.Object('Subscription_v4'))
    subscriptionV4Query4.greaterThanOrEqualTo('periodExpirationDate', now)
    subscriptionV4Query4.greaterThanOrEqualTo('planCancelDate', now)
    userQuery5.matchesQuery('subscription_v4', subscriptionV4Query4)
    userQuery5.exists('subscription_v4')

    // Query is, in English:
    // If a legacy subscription is present, is it active and has level == 1?
    // OR if a v4 subscription is present, is it active?
    // OR if a v4 subscription is present, is it in a trial?
    const orQuery = Parse.Query.or(userQuery, userQuery2, userQuery3, userQuery4, userQuery5)
    orQuery.withCount(true)
    orQuery.limit(pageSize)
    orQuery.skip(skip)
    return orQuery
  }

  allSubscriberQuery = (userQuery, skip, pageSize) => {
    userQuery.exists('subscription')

    const userQuery2 = this.baseQuery(skip, pageSize)
    userQuery2.exists('subscription_v4')

    const orQuery = Parse.Query.or(userQuery, userQuery2)
    orQuery.withCount(true)
    orQuery.limit(pageSize)
    orQuery.skip(skip)
    return orQuery
  }

  // used to sharre filtering logic between count & query methods
  currentQuery = (pageSize) => {
    const skip = this.state.skip

    const userQuery = this.baseQuery(skip, pageSize)
    if (this.state.premiumFilter === '1') {
      console.log('Query for all subscribers')
      return this.subscriberQuery(userQuery, skip, pageSize)
    } else if (this.state.premiumFilter === '2') {
      console.log('Query for all free members')
      const innerQuery = this.subscriberQuery(userQuery, skip, pageSize)
      const nonsubscriberQuery = this.baseQuery(skip, pageSize)
      nonsubscriberQuery.doesNotMatchKeyInQuery('objectId', 'objectId', innerQuery)
      nonsubscriberQuery.withCount(true)
      nonsubscriberQuery.limit(pageSize)
      nonsubscriberQuery.skip(skip)
      return nonsubscriberQuery
    } else if (this.state.premiumFilter === '3') {
      console.log('Query for all ELITE Coach subscribers')
      return this.eliteCoachQuery(userQuery, skip, pageSize)
    } else if (this.state.premiumFilter === '4') {
      console.log('Query for Dryland pack subscribers')
      return this.drylandPackQuery(userQuery, skip, pageSize)
    } else if (this.state.premiumFilter === '5') {
      console.log('Query for Swim pack subscribers')
      return this.swimmingPackQuery(userQuery, skip, pageSize)
    } else if (this.state.premiumFilter === '6') {
      console.log('Query for all subscribers, including inactive ones')
      return this.allSubscriberQuery(userQuery, skip, pageSize)
    } else if (this.state.premiumFilter === '7') {
      console.log('Query for members who never started a trial')
      const nosubExistsQuery = this.baseQuery(skip, pageSize)
      nosubExistsQuery.doesNotExist('subscription_v4')
      return nosubExistsQuery
    }

    console.log('Query for all members')
    return userQuery
  }

  queryEmailCount = () => {
    console.log('Query for count')
    const userQuery = this.currentQuery(1)
    userQuery.limit(1)
    this.setState({
      isLoading: true,
      totalCount: 0
    })
    userQuery.find().then((resultDict) => {
      if (resultDict !== undefined && resultDict.count !== undefined) {
        this.setState({
          totalCount: this.state.totalCount + resultDict.count,
          isLoading: false
        })
      } else {
        this.setState({
          totalCount: 0,
          isLoading: false
        })
      }
    })
  }

  queryEmails = async () => {
    const startDate = new Date()
    console.log('querying emails, time: ' + startDate)
    const skip = this.state.skip
    let totalCount = 0
    let infoMap = this.state.infoMap === undefined ? {} : this.state.infoMap
    let resultCount = 0

    if ((skip % maxCSVSize) === 0 && skip > 0) {
      console.log('DUMPING: ' + skip % maxCSVSize)
      // Dump to file every 100k rows to save memory
      this.downloadFile(skip / maxCSVSize)
      infoMap = {}
      this.setState({
        infoMap: infoMap
      })
    } else {
      console.log('Not dumping: ' + skip + ' rows')
    }

    if (skip !== 0 && this.state.isLoading === false) {
      const endDate = new Date()
      const elapsedTime = endDate.getTime() - this.state.startDate.getTime()
      // divide by 1000 since elapsedTime starts as miliseconds
      console.log('full fetch took: ' + formatSeconds(elapsedTime / 1000))
      this.downloadFile('final')
      this.setState({
        skip: 0,
        totalCount: 0,
        infoMap: {},
        startDate: undefined
      })
      return
    }

    const userQuery = this.currentQuery(pageSize)

    // only reset total count on first call to this function
    if (skip === 0) {
      this.setState({
        isLoading: true,
        totalCount: 0
      })
    }

    let userResults = []
    const subFetchPromises = []

    userQuery.find().then(async (resultDict) => {
      userResults = resultDict.results || resultDict
      if (resultDict.count) {
        totalCount = resultDict.count
      } else {
        totalCount = this.state.totalCount
      }
      resultCount = userResults.length

      for (let i = 0; i < userResults.length; i++) {
        const user = userResults[i]
        const email = user.get('email') || user.get('username')
        infoMap[user.id] = {
          email: email.replace(',', ''),
          firstName: '',
          lastName: '',
          totalWorkoutsCompleted: 0,
          skillLevel: getSkillLevelString(user.get('skillLevel')),
          stravaLinked: (user.get('stravaAccessToken') !== undefined),
          garminLinked: (user.get('garminAccessToken') !== undefined),
          trialStartDate: 'N/A',
          trialEndDate: 'N/A',
          locale: '',
          isAdTrackingDisabled: user.get('isAdTrackingDisabled')
        }

        let sub = user.get('subscription_v4')
        if (!sub) {
          sub = user.get('subscription')
        }

        if (sub) {
          const applySubToUserInfo = (sub) => {
            infoMap[user.id].eliteOverride = sub.get('eliteOverride') || false

            if (sub.get('trialEndDate')) {
              infoMap[user.id].trialEndDate = sub.get('trialEndDate').toISOString()
            }
            if (sub.get('trialStartDate')) {
              infoMap[user.id].trialStartDate = sub.get('trialStartDate').toISOString()
            }
            if (sub.get('periodStartDate')) {
              infoMap[user.id].periodStartDate = sub.get('periodStartDate').toISOString()
            }
            if (sub.get('periodExpirationDate')) {
              infoMap[user.id].periodExpirationDate = sub.get('periodExpirationDate').toISOString()
            }
            if (sub.get('planId')) {
              infoMap[user.id].planId = sub.get('planId')
            }

            if (sub.get('planId')) {
              let isSubscriptionActive = false
              if (sub.get('periodExpirationDate')) {
                isSubscriptionActive = sub.get('periodExpirationDate') > new Date()
              }
              if (isSubscriptionActive && sub.get('planId').includes('_dryland')) {
                infoMap[user.id].subStatus = 'DRYLAND'
                infoMap[user.id].upgraded = false
              } else if (isSubscriptionActive && sub.get('planId').includes('_swim')) {
                infoMap[user.id].subStatus = 'SWIM'
                infoMap[user.id].upgraded = false
              } else if (isSubscriptionActive && sub.get('planId').includes('_coach')) {
                infoMap[user.id].subStatus = 'COACH'
                infoMap[user.id].upgraded = false
              } else if (isSubscriptionActive && sub.get('eliteOverride')) {
                infoMap[user.id].subStatus = 'COACH'
                infoMap[user.id].upgraded = true
              } else if (isSubscriptionActive) {
                infoMap[user.id].subStatus = 'SWIM'
                infoMap[user.id].upgraded = true
              } else {
                infoMap[user.id].subStatus = 'FREE'
                infoMap[user.id].upgraded = false
              }
            } else {
              infoMap[user.id].subStatus = 'FREE'
              infoMap[user.id].upgraded = false
            }
          }

          if (!sub.isDataAvailable()) {
            const fetchPromise = sub.fetch().then((sub) => {
              applySubToUserInfo(sub)
            })
            subFetchPromises.push(fetchPromise)
          } else {
            applySubToUserInfo(sub)
          }
        } else {
          infoMap[user.id].subStatus = 'FREE'
          infoMap[user.id].upgraded = false
        }

        const userInfo = user.get('userInfo_v3')
        if (userInfo) {
          const firstName = userInfo.get('firstName')
          const lastName = userInfo.get('lastName')
          const totalWorkoutsCompleted = userInfo.get('totalWorkoutsCompleted')
          infoMap[user.id].firstName = firstName
          infoMap[user.id].lastName = lastName
          if (totalWorkoutsCompleted) {
            infoMap[user.id].totalWorkoutsCompleted = totalWorkoutsCompleted
          }
        }
      }

      await Promise.all(subFetchPromises)

      // This recursively calls itself, incrementing skip until we are out of results
      // Be careful of side effects in beginning of this method as a result
      this.setState({
        infoMap: infoMap,
        isLoading: (resultCount === pageSize),
        skip: skip + pageSize,
        totalCount: totalCount,
        startDate: this.state.startDate === undefined ? startDate : this.state.startDate
      }, this.queryEmails)
    }, (error) => {
      console.log(error)
      this.setState({
        isLoading: false,
        errorMessage: 'An error occurred, please try again'
      })
    })
  }

  updatePremiumFilter = (event) => {
    console.log('update elite filter: ' + event.target.value)
    this.setState({
      premiumFilter: event.target.value
    })
  }

  onCreatedBeforeDateChange = (event) => {
    const selectedDate = event.target.value
    console.log('selected created before Date: ' + selectedDate)
    const dateComponents = selectedDate.split('-')
    this.setState({
      createdBeforeDate: new Date(dateComponents[0], dateComponents[1] - 1, dateComponents[2])
    })
  }

  onCreatedAfterDateChange = (event) => {
    const selectedDate = event.target.value
    console.log('selected created after Date: ' + selectedDate)
    const dateComponents = selectedDate.split('-')
    this.setState({
      createdAfterDate: new Date(dateComponents[0], dateComponents[1] - 1, dateComponents[2])
    })
  }

  onStartEnabledChange = (event) => {
    const isEnabled = event.target.checked
    this.setState({ isStartDateEnabled: isEnabled })
  }

  onEndEnabledChange = (event) => {
    const isEnabled = event.target.checked
    this.setState({ isEndDateEnabled: isEnabled })
  }

  onAdTrackingChange = (event) => {
    const isFilterEnabled = event.target.checked
    this.setState({ isAdTrackingDisabledFilterOn: isFilterEnabled })
  }

  render = () => {
    let formattedCreatedBeforeDate
    let formattedCreatedAfterDate

    if (!this.state.createdBeforeDate) {
      formattedCreatedBeforeDate = new Date().toISOString().slice(0, 10)
    } else {
      const dateToUse = this.state.createdBeforeDate
      formattedCreatedBeforeDate = dateToUse.getFullYear() + '-' +
            ((dateToUse.getMonth() + 1) < 10 ? '0' : '') + ((dateToUse.getMonth() + 1)) + '-' +
            (dateToUse.getDate() < 10 ? '0' : '') + dateToUse.getDate()
    }

    if (!this.state.createdAfterDate) {
      formattedCreatedAfterDate = new Date().toISOString().slice(0, 10)
    } else {
      const dateToUse = this.state.createdAfterDate
      formattedCreatedAfterDate = dateToUse.getFullYear() + '-' +
            ((dateToUse.getMonth() + 1) < 10 ? '0' : '') + ((dateToUse.getMonth() + 1)) + '-' +
            (dateToUse.getDate() < 10 ? '0' : '') + dateToUse.getDate()
    }

    return (
            <div className="container">
                <br/>
                <div className="row">
                    <div className="col-12">
                            <div className="card">
                                <h5 className="card-header">Email Lookup</h5>
                                <div className="card-body">
                                    <label><b>Elite Filter:</b></label>
                                    <select className="form-control" value={this.state.premiumFilter} onChange={this.updatePremiumFilter}>
                                        <option value="0">All Members</option>
                                        <option value="1">All Subscribers</option>
                                        <option value="2">Free Members</option>
                                        <option value="3">ELITE Coach Subscribers</option>
                                        <option value="4">Dryland Pack Subscribers</option>
                                        <option value="5">Swimming Pack Subscribers</option>
                                        <option value="6">All Subscribers (Including inactive)</option>
                                        <option value="7">Never started a trial or subscription</option>
                                    </select>
                                    <br/>
                                    <label>
                                      <input type="checkbox" checked={this.state.isStartDateEnabled} onChange={this.onStartEnabledChange}></input>&nbsp;
                                      <b>Account Created After: &nbsp;</b>
                                    </label>
                                    <input type="date" value={formattedCreatedAfterDate} onChange={this.onCreatedAfterDateChange} />
                                    <br/>
                                    <label>
                                      <input type="checkbox" checked={this.state.isEndDateEnabled} onChange={this.onEndEnabledChange}></input>&nbsp;
                                      <b>Account Created Before: &nbsp;</b>
                                    </label>
                                    <input type="date" value={formattedCreatedBeforeDate} onChange={this.onCreatedBeforeDateChange} />
                                    <br/>
                                    <label>
                                      <input type="checkbox" checked={this.state.isAdTrackingDisabledFilterOn} onChange={this.onAdTrackingChange}></input>&nbsp;
                                      <b>List will be used for ad tracking </b><span className="text-muted">This will exclude accounts that opted-out of ad tracking</span>
                                    </label>
                                </div>
                                <div className="card-body">
                                {
                                    this.state.isLoading === true
                                      ? (this.state.totalCount > 0
                                          ? <p>Loading... retrieved {this.state.skip} of {this.state.totalCount} ({Math.round((this.state.skip / this.state.totalCount) * 100)}%)</p>
                                          : <p>Loading... (0%)</p>)
                                      : this.state.errorMessage ? <p>{this.state.errorMessage}</p> : null
                                }
                                {
                                    this.state.isLoading === true && this.state.totalCount > maxCSVSize
                                      ? <i>Note: CSVs will be downloaded in 100k-row chunks</i>
                                      : null
                                }
                                </div>
                            </div>
                            <div className="card-footer">
                                <form id="email-lookup-form" className="form-inline">
                                <div className="form-row">
                                    <div className="col">
                                        {this.state.totalCount > 0 ? this.state.totalCount + ' total' : null}
                                    </div>
                                    <div className="col">
                                        <button type="button" className="btn btn-primary" onClick={this.queryEmailCount} disabled={this.state.isLoading}>Check Count</button>
                                    </div>
                                    <div className="col">
                                        <button type="button" className="btn btn-primary" onClick={this.queryEmails} disabled={this.state.isLoading}>Download Emails</button>
                                    </div>
                                </div>
                                </form>
                            </div>
                    </div>
                </div>
            </div>
    )
  }
}
