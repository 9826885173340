// Utils
import Parse from 'parse'

// Enum conversion utils
export const getSkillLevelString = (skillLevel) => {
  switch (skillLevel) {
    case 0:
      return 'Level 1'
    case 1:
      return 'Level 2'
    case 2:
      return 'Level 3'
    case 3:
      return 'Level 4'
    case 4:
      return 'Level 5'
    default:
      return 'Invalid (' + skillLevel + ')'
  }
}

export const getPoolCourseString = (poolCourse) => {
  switch (poolCourse) {
    case 0:
      return 'SCY'
    case 1:
      return 'SCM'
    case 2:
      return 'LCM'
    default:
      return 'Invalid (' + poolCourse + ')'
  }
}

export const getZoneString = (zone) => {
  switch (zone) {
    case 1:
      return 'Z1 - Easy'
    case 2:
      return 'Z2 - Moderate'
    case 3:
      return 'Z3 - Endurance'
    case 4:
      return 'Z4 - Threshold'
    case 5:
      return 'Z5 - Best Average'
    case 6:
      return 'Z6 - Race Pace'
    case 7:
      return 'Z7 - Sprint'
    default:
      return 'Invalid (' + zone + ')'
  }
}

export const getZoneName = (zone) => {
  if (zone === undefined) return 'No Zone'
  switch (zone) {
    case 0:
      return 'No Zone'
    case 1:
      return 'Easy'
    case 2:
      return 'Moderate'
    case 3:
      return 'Endurance'
    case 4:
      return 'Threshold'
    case 5:
      return 'Best Average'
    case 6:
      return 'Race Pace'
    case 7:
      return 'Sprint'
    default:
      return 'Unknown (' + zone + ')'
  }
}

export const zoneList = [
  {
    label: 'No Zone',
    value: 0
  },
  {
    label: 'Easy',
    value: 1
  },
  {
    label: 'Moderate',
    value: 2
  },
  {
    label: 'Endurance',
    value: 3
  },
  {
    label: 'Threshold',
    value: 4
  },
  {
    label: 'Best Average',
    value: 5
  },
  {
    label: 'Race Pace',
    value: 6
  },
  {
    label: 'Sprint',
    value: 7
  }
]

export const getModeString = (value) => {
  const num = parseInt(value)
  switch (num) {
    case 1:
      return 'Pool'
    case 2:
      return 'Open Water'
    case 3:
      return 'Dryland'
    case 4:
      return 'Mixed'
    default:
      return 'Unknown (' + value + ')'
  }
}

export const getZoneColor = (zone) => {
  switch (zone) {
    case 1:
      return '#80FF80'
    case 2:
      return '#FFFF4D'
    case 3:
      return '#FFCCEE'
    case 4:
      return 'red'
    case 5:
      return '#0091EA'
    case 6:
      return '#A64DFF'
    case 7:
      return '#DAA520'
    default:
      return '#FFFFFF'
  }
}

export const getStrokeString = (stroke) => {
  switch (parseInt(stroke)) {
    case 0:
      return 'Free'
    case 1:
      return 'Back'
    case 2:
      return 'Breast'
    case 3:
      return 'Fly'
    case 4:
      return 'IM'
    case 5:
      return 'Kick'
    case 6:
      return 'Pull'
    case 7:
      return 'Drill'
    case 8:
      return 'Stroke'
    case 9:
      return 'FRIM'
    default:
      return 'Invalid (' + stroke + ')'
  }
}

export const strokeList = [
  {
    label: 'Free',
    value: 0
  },
  {
    label: 'Back',
    value: 1
  },
  {
    label: 'Breast',
    value: 2
  },

  {
    label: 'Fly',
    value: 3
  },
  {
    label: 'IM',
    value: 4
  },
  {
    label: 'Kick',
    value: 5
  },
  {
    label: 'Pull',
    value: 6
  },
  {
    label: 'Drill',
    value: 7
  },
  {
    label: 'Stroke',
    value: 8
  },
  {
    label: 'FRIM',
    value: 9
  }
]

export const getWorkoutTypeString = (type) => {
  switch (type) {
    case 0:
      return 'Free'
    case 1:
      return 'Distance'
    case 2:
      return 'Sprint'
    case 3:
      return 'Kick'
    case 4:
      return 'IM'
    case 5:
      return 'Fly'
    case 6:
      return 'Back'
    case 7:
      return 'Breast'
    case 8:
      return 'Upper Body'
    case 9:
      return 'Lower Body'
    case 10:
      return 'Total Body'
    case 11:
      return 'Shoulders'
    case 12:
      return 'Core'
    default:
      return 'Invalid (' + type + ')'
  }
}

// Time utils
export const formatSeconds = (seconds) => {
  if (!seconds) {
    return '0'
  }

  if (seconds >= 3600) {
    // more than 1 hour
    const hours = Math.floor(seconds / 3600)
    let remainingSeconds = seconds - hours * 3600
    const minutes = minutesFromInterval(remainingSeconds)
    remainingSeconds = remainingSeconds - minutes * 60
    return (
      hours.toString() +
      ':' +
      formatTimeComponentHelper(minutes) +
      ':' +
      formatTimeComponentHelper(remainingSeconds)
    )
  } else if (seconds >= 60) {
    // more than 1 minute
    const minutes = minutesFromInterval(seconds)
    const remainingSeconds = seconds - minutes * 60
    return (
      minutes.toString() + ':' + formatTimeComponentHelper(remainingSeconds)
    )
  }

  return formatTimeComponentHelper(seconds.toString())
}

export const minutesFromInterval = (interval) => {
  return Math.floor(interval / 60)
}

export const secondsFromInterval = (interval) => {
  return interval % 60
}

const formatTimeComponentHelper = (timeComponent) => {
  if (timeComponent >= 10) {
    return parseInt(timeComponent, 10).toString()
  }
  return '0' + parseInt(timeComponent, 10).toString()
}

/**
 * Creates and saves a deep copy of the passed in plan, including workouts.
 * @param {ParseObject} inputPlan AdminTrainingPlan or TrainingPlan
 * @returns {ParseObject} new plan, saved
 */
export const clonePlan = async (inputPlan) => {
  const planQuery = new Parse.Query(inputPlan.className)
  planQuery.include('trainingPlanWeeks.entries')
  const plan = await planQuery.get(inputPlan.id)
  const newPlan = plan.clone()
  const weeks = plan.get('trainingPlanWeeks') || []
  const newWeekPromises = weeks.map(async (week) => {
    const entries = week.get('entries') || []
    const newEntriesPromises = entries.map(async (entry) => {
      const newEntry = entry.clone()
      const lcmWorkout = entry.get('lcmWorkout')
      const newLcmWorkout = await cloneWorkout(lcmWorkout)
      newEntry.set('lcmWorkout', newLcmWorkout)
      const scyWorkout = entry.get('scyWorkout')
      const newScyWorkout = await cloneWorkout(scyWorkout)
      newEntry.set('scyWorkout', newScyWorkout)
      await newEntry.save()
      return newEntry
    })
    const newEntries = await Promise.all(newEntriesPromises)
    const newWeek = week.clone()
    newWeek.set('entries', newEntries)
    await newWeek.save()
    return newWeek
  })
  const newWeeks = await Promise.all(newWeekPromises)
  newPlan.set('trainingPlanWeeks', newWeeks)

  // We need to manually copy pointers
  newPlan.set('nextPlan', plan.get('nextPlan'))
  newPlan.set('skillLevels', plan.get('skillLevels'))
  newPlan.set('goals', plan.get('goals'))
  await newPlan.save()
  return newPlan
}

/**
 * Creates and saves a deep copy of the passed in workout, down to the sets.
 * @param {ParseObject} inputWorkout AdminWorkout or Workout_v3
 * @returns {ParseObject} new workout, saved
 */
export const cloneWorkout = async (inputWorkout) => {
  const workoutQuery = new Parse.Query(inputWorkout.className)
  workoutQuery.include('setGroups.sets')
  const workout = await workoutQuery.get(inputWorkout.id)
  const newWorkout = workout.clone()
  const setGroups = workout.get('setGroups') || []
  const newSetGroupPromises = setGroups.map(async (setGroup) => {
    return await cloneSetGroup(setGroup)
  })
  const newSetGroups = await Promise.all(newSetGroupPromises)
  newWorkout.set('setGroups', newSetGroups)
  await newWorkout.save()
  return newWorkout
}

/**
 * Creates and saves a deep copy of the passed in setgroup, down to the sets.
 * @param {ParseObject} setGroup AdminWorkout or Workout_v3
 * @returns {Promise} new SetGroup save() promise
 */
export const cloneSetGroup = async (setGroup) => {
  const sets = setGroup.get('sets') || []
  const newSetPromises = await sets.map(async (set) => {
    const newSet = set.clone()
    await newSet.save()
    return newSet
  })
  const newSets = await Promise.all(newSetPromises)
  const newSetGroup = setGroup.clone()
  newSetGroup.set('sets', newSets)
  return newSetGroup.save()
}

// Workout utils
export const copyWorkout = (originId, destinationId, className) => {
  console.log('Copying workout...')

  if (originId == null || destinationId == null) {
    window.alert('No workout ids')
    return
  }

  const query = new Parse.Query(Parse.Object(className))
  query.include('setGroups.sets.video')
  return query
    .get(originId)
    .then((originWorkout) => {
      const workout = getJSONWorkout(originWorkout)
      return Parse.Cloud.run('updateWorkout_v4', {
        workout: workout,
        id: destinationId,
        isAdmin: true
      })
    })
    .then(
      (result) => {
        console.log('Workout copied.')
      },
      (error) => {
        window.alert('Error copying workout: ' + error)
      }
    )
}

export const getJSONWorkout = (workoutObject) => {
  if (workoutObject == null) {
    return
  }

  const workoutModel = {}

  workoutModel.skillLevel = workoutObject.get('skillLevel')
  workoutModel.poolCourse = workoutObject.get('poolCourse')
  workoutModel.workoutType = workoutObject.get('workoutType')
  workoutModel.title = workoutObject.get('title')
  workoutModel.comments = workoutObject.get('comments')
  workoutModel.imageID = workoutObject.get('imageID')
  workoutModel.isPremium = workoutObject.get('isPremium')
  workoutModel.isAdminWorkout = workoutObject.get('isAdminWorkout')
  workoutModel.isTestSetWorkout = workoutObject.get('isTestSetWorkout')
  workoutModel.totalDistance = workoutObject.get('totalDistance')
  workoutModel.totalTime = workoutObject.get('totalTime')
  workoutModel.isAllStrength = workoutObject.get('isAllStrength')

  const setGroupModels = []
  const setGroups = workoutObject.get('setGroups') || []
  for (let i = 0; i < setGroups.length; i++) {
    const setGroupObject = setGroups[i]
    const setGroupModel = {}

    // SetGroup population
    setGroupModel.index = setGroupObject.get('index')
    setGroupModel.title = setGroupObject.get('title')
    setGroupModel.reps = setGroupObject.get('reps')

    const setModels = []
    for (let j = 0; j < setGroupObject.get('sets').length; j++) {
      const setObject = setGroupObject.get('sets')[j]
      const setModel = {}

      // Set population
      setModel.distance = setObject.get('distance')
      setModel.interval = setObject.get('interval')
      setModel.stroke = setObject.get('stroke')
      setModel.mediaURL = setObject.get('mediaURL')
      setModel.notes = setObject.get('notes')
      setModel.index = setObject.get('index')
      setModel.reps = setObject.get('reps')
      setModel.zone = setObject.get('zone')
      setModel.isTestSet = setObject.get('isTestSet')
      setModel.setType = setObject.get('setType')
      setModel.exerciseType = setObject.get('exerciseType')
      setModel.videoId = setObject.get('video')
        ? setObject.get('video').id
        : undefined
      setModel.equipment = setObject.get('equipment')
      setModel.effortVariation = setObject.get('effortVariation')

      setModels.push(setModel)
    }
    setGroupModel.sets = setModels

    setGroupModels.push(setGroupModel)
  }
  workoutModel.setGroups = setGroupModels

  return workoutModel
}

export const getGoalName = (goal) => {
  switch (goal) {
    case 'goal_speed':
      return 'Enhance Speed'
    case 'goal_endurance':
      return 'Improve Endurance'
    case 'goal_technique':
      return 'Improve Technique'
    case 'goal_weight':
      return 'Lose Weight'
    case 'goal_im_training':
      return 'Individual Medley Training'
    case 'goal_learn_strokes':
      return 'Learn the strokes'
    case 'goal_open_water':
      return 'Open Water Racing'
    case 'goal_log':
      return 'Keep a Swim Log'
    case 'goal_getStarted':
      return 'Get Started'
    case 'goal_openWater5k':
      return '5 km Open Water Race'
    case 'goal_openWater10k':
      return '10 km Open Water Race'
    case 'goal_lowImpact':
      return 'Low Impact Exercise'
    case 'goal_maintainFitness':
      return 'Maintain Fitness'
    case 'goal_coreStrength':
      return 'Core Strength'
    case 'goal_getStronger':
      return 'Get Stronger'
    default:
      return goal
  }
}

export const dataFromReferenceDate = (input) => {
  const iOSReference = new Date(Date.UTC(2001, 0, 1, 0, 0, 0, 0))
  const normalizedTimestamp = input + iOSReference.getTime() / 1000
  const returnDate = new Date(0)
  returnDate.setUTCSeconds(normalizedTimestamp)
  return returnDate
}

// PTI utils
export const convertPTI = (interval, isIntervalInMeters, isPreferredPoolCourseInMeters) => {
  if (isIntervalInMeters === isPreferredPoolCourseInMeters) {
    return interval
  } else if (isIntervalInMeters) {
    return Math.round(interval * 0.9144)
  } else {
    return Math.round(interval * 1.09361)
  }
}
