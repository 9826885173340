import React from 'react'
import Parse from 'parse'
import 'bootstrap/dist/css/bootstrap.min.css'

export default class UserInfoDisplay extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      id: this.props.match ? this.props.match.params.id : '',
      username: '',
      created: '',
      hasSubscription: false,
      subscriptionId: null,
      editingPeriodEndDate: false,
      history: this.props.history,
      users: []
    }

    if (this.props.match && this.props.match.params.id) {
      this.handleButtonClick()
    }
  }

  handleEmailTextFieldChange = (event) => {
    this.setState({
      searchedEmail: event.target.value.trim()
    })
  }

  handleFirstNameTextFieldChange = (event) => {
    this.setState({
      searchedFirstName: event.target.value.trim()
    })
  }

  handleLastNameTextFieldChange = (event) => {
    this.setState({
      searchedLastName: event.target.value.trim()
    })
  }

  handleIdTextFieldChange = (event) => {
    this.setState({
      searchedId: event.target.value.trim()
    })
  }

  createSearchQuery = () => {
    const searchDict = {
      email: this.state.searchedEmail,
      first: this.state.searchedFirstName,
      last: this.state.searchedLastName,
      id: this.state.searchedId ? this.state.searchedId : this.state.id
    }
    console.log('createSearchQuery: ')
    console.log(searchDict)

    // currently supported token types:
    // - user email (User table)
    // - user objectId (User table)
    // - user first name (UserInfo table)
    // - user last name (UserInfo table)
    const emailIdQuery = new Parse.Query(Parse.User)
    emailIdQuery.include(['subscription_v4'])
    emailIdQuery.limit(100)
    emailIdQuery.withCount(true)

    let searchId = false
    let searchEmail = false

    if (searchDict.email !== undefined && searchDict.email.includes('@')) {
      // explicitly look for email
      // take @-> end as hard search, fuzzy outside of that
      const searchTerm = searchDict.email
      const stringLength = searchTerm.length
      const suffixStartIndex = searchTerm.indexOf('@')
      const emailSuffix = searchTerm.substring(suffixStartIndex, searchTerm.length)

      const first3 = searchTerm.substring(0, ((stringLength - (stringLength - suffixStartIndex)) > 3) ? 3 : (stringLength - (stringLength - suffixStartIndex)))
      let emailRegex = ''
      emailRegex = '(^' + first3 + '.{' + Math.max(0, (stringLength - (stringLength - suffixStartIndex) - 6)).toString() + ',' + (stringLength - (stringLength - suffixStartIndex) - 3).toString() + '}' + emailSuffix + '$)'
      console.log('Regex for email search token: ' + emailRegex)
      emailIdQuery.matches('email', emailRegex, 'i')
      searchEmail = true
    } else if (searchDict.email !== undefined) {
      const tokenRegex = '(' + searchDict.email + ')'
      console.log('Regex for email search token: ' + tokenRegex)
      emailIdQuery.matches('email', tokenRegex, 'i')
      searchEmail = true
    }

    if (searchDict.id !== undefined) {
      const tokenRegex = '(' + searchDict.id + ')'
      console.log('Regex for id search token: ' + tokenRegex)
      emailIdQuery.matches('objectId', tokenRegex, 'i')
      searchId = true
    }

    let nameQuery
    let searchFirstName = false
    let searchLastName = false
    nameQuery = new Parse.Query(Parse.Object('UserInfo_v3'))
    nameQuery.include(['parentUser'])
    nameQuery.limit(100)

    if (searchDict.first !== undefined) {
      searchFirstName = true
      const tokenRegex = '(' + searchDict.first + ')'
      console.log('Regex for firstName search token: ' + tokenRegex)
      nameQuery.matches('firstName', tokenRegex, 'i')
    }

    if (searchDict.last !== undefined) {
      searchLastName = true
      const tokenRegex = '(' + searchDict.last + ')'
      console.log('Regex for lastName search token: ' + tokenRegex)
      nameQuery.matches('lastName', tokenRegex, 'i')
    }

    if (!searchFirstName && !searchLastName) {
      if (!searchEmail && !searchId) {
        return undefined
      }
      return emailIdQuery
    }

    return nameQuery.find().then((userInfos) => {
      console.log('found user infos: ' + userInfos.length)
      if (!userInfos) {
        return emailIdQuery
      }

      const userInfoMap = this.state.userInfoMap ? this.state.userInfoMap : {}

      const userIds = userInfos.filter((userInfo) => { return userInfo.get('parentUser') })
        .map((userInfo) => {
          const userId = userInfo.get('parentUser').id
          userInfoMap[userId] = userInfo
          return userId
        })
      emailIdQuery.containedIn('objectId', userIds)

      this.setState({
        userInfoMap: userInfoMap
      })
      return emailIdQuery
    })
  }

  handleSearch = () => {
    const searchPromise = this.createSearchQuery()
    console.log('handleSearch: ')
    console.log(searchPromise)
    if (!searchPromise) {
      alert("Remember to use the prefixes! Available prefixes: 'email:', 'id:', 'first:', 'last:'")
      return
    }

    if (typeof searchPromise.then === 'function') {
      searchPromise.then((query) => {
        return query.find().then((results) => {
          console.log('mega search query results: ')
          console.log(results)
          return this.handleSearchResults(results)
        })
      })
    } else {
      return searchPromise.find().then((results) => {
        console.log('mega search query results: ')
        console.log(results)
        return this.handleSearchResults(results)
      })
    }
  }

  handleSearchResults = (results) => {
    // results is an array of result dictionaries
    const users = results.results
    const totalCount = results.count

    console.log('query results, count of results: ' + totalCount)
    console.log(users)

    if (!users || totalCount === 0) {
      this.setState({
        id: '',
        username: '',
        created: '',
        hasSubscription: false,
        subscriptionId: null,
        editingPeriodEndDate: false,
        users: [],
        totalCount: 0
      })
      alert('No users found.')
      return
    }

    const user = users[0]
    console.log('first user: ' + user)
    const sub = user.get('subscription_v4')

    if (sub) {
      this.setState({
        id: user.id,
        selectedUser: user,
        username: user.get('username'),
        created: user.get('createdAt') !== undefined ? user.get('createdAt').toString() : '',
        hasSubscription: true,
        subscriptionId: sub.id,
        subscription: sub,
        clearSubscriptionStatusMessage: null,
        users: users,
        totalCount: totalCount
      })
    } else {
      this.setState({
        id: user.id,
        selectedUser: user,
        username: user.get('username'),
        created: user.get('createdAt') !== undefined ? user.get('createdAt').toString() : '',
        hasSubscription: false,
        subscriptionId: null,
        clearSubscriptionStatusMessage: null,
        users: users,
        totalCount: totalCount
      })
    }
  }

  handleButtonClick = (event) => {
    console.log('handling search...')

    this.setState({
      userInfoMap: undefined
    })
    return this.handleSearch()
  }

  handleEditPeriodEndDate = () => {
    if (this.state.editingPeriodEndDate) {
      const subscription = this.state.subscription
      subscription.set('periodExpirationDate', this.state.newPeriodEndDate)
      subscription.save().then(() => {
        this.setState({
          editingPeriodEndDate: false,
          newPeriodEndDate: undefined,
          subscription: subscription
        })
      })
    } else {
      this.setState({
        editingPeriodEndDate: true
      })
    }
  }

  onPeriodEndDateChange = (event) => {
    const selectedDate = event.target.value
    console.log('onPeriodEndDateChange: ' + selectedDate)
    const dateComponents = selectedDate.split('-')
    this.setState({
      newPeriodEndDate: new Date(dateComponents[0], dateComponents[1] - 1, dateComponents[2])
    })
  }

  selectUser = (event, index) => {
    event.preventDefault()
    const users = this.state.users
    if (users.length < index) {
      return
    }
    const newUser = users[index]
    const newSub = newUser.get('subscription_v4')
    console.log('new user subscription: ' + newSub + ' with id ' + (newSub ? newSub.id : ' undefined'))
    this.setState({
      id: newUser.id,
      selectedUser: newUser,
      username: newUser.get('username'),
      created: newUser.get('createdAt') !== undefined ? newUser.get('createdAt').toString() : '',
      hasSubscription: (newSub !== undefined),
      subscriptionId: (newSub !== undefined) ? newSub.id : undefined,
      subscription: newSub,
      clearSubscriptionStatusMessage: null
    })
  }

  handleClearSubscriptionClick = (event) => {
    console.log('clear subscription clicked')

    const subscriptionId = this.state.subscriptionId
    if (!subscriptionId) {
      this.setState({
        clearSubscriptionStatusMessage: 'No subscription to clear.'
      })
      return
    }

    const shouldClear = window.confirm("Are you sure you want to clear this user's subscription info? It may affect their access to locked content until they update it manually.")

    if (shouldClear) {
      const query = new Parse.Query('Subscription_v4')
      query.get(subscriptionId).then((subscription) => {
        subscription.destroy().then(() => {
          this.setState({
            id: this.state.id,
            username: this.state.username,
            created: this.state.created,
            hasSubscription: false,
            subscriptionId: null,
            clearSubscriptionStatusMessage: null,
            subscription: undefined
          })
          this.setState({
            clearSubscriptionStatusMessage: 'Subscription info cleared.'
          })
        }, function (error) {
          this.setState({
            clearSubscriptionStatusMessage: 'Error clearing subscription: ' + error.message
          })
        })
      })
    }
  }

  handleEliteOverrideChange = (event) => {
    event.preventDefault()
    const sub = this.state.selectedUser.get('subscription_v4')
    if (sub) {
      sub.set('eliteOverride', !sub.get('eliteOverride'))
      sub.save().then(() => {
        console.log('saved sub ' + sub.id)
        this.setState({
          subscription: sub
        })
      })
    } else {
      console.log('no sub')
    }
  }

  renderTable = () => {
    console.log(this.state.users)
    const userInfoMap = this.state.userInfoMap
    return this.state.users.map((user, index) => {
      return (
                <tr key={user.id} className="data" onClick={ e => this.selectUser(e, index)}>
                    <td>{user.id}</td>
                    <td>{user.get('email')}</td>
                    <td>{(userInfoMap && userInfoMap[user.id]) ? userInfoMap[user.id].get('firstName') : ''}</td>
                    <td>{(userInfoMap && userInfoMap[user.id]) ? userInfoMap[user.id].get('lastName') : ''}</td>
                    <td>{user.get('createdAt').toString()}</td>
                </tr>
      )
    })
  }

  renderTableHeader = () => {
    return (
            <tr key={'0-header'} className="data">
                <th className="bg-primary" key={0}>{'User Id'}</th>
                <th className="bg-primary" key={1}>{'Email'}</th>
                <th className="bg-primary" key={2}>{'First Name'}</th>
                <th className="bg-primary" key={3}>{'Last Name'}</th>
                <th className="bg-primary" key={8}>{'Created Date'}</th>
            </tr>
    )
  }

  render = () => {
    const subscription = this.state.subscription
    let planName, creationSource, stripeCustomerId, stripeSubscriptionId, trialStart, trialEnd, periodStart, periodEnd, eliteOverride
    if (subscription !== undefined) {
      planName = subscription.get('planId')
      eliteOverride = subscription.get('eliteOverride')
      creationSource = subscription.get('creationSource')
      stripeCustomerId = subscription.get('stripeCustomerId')
      stripeSubscriptionId = subscription.get('stripeSubscriptionId')
      trialStart = subscription.get('trialStartDate') !== undefined ? subscription.get('trialStartDate').toString() : ''
      trialEnd = subscription.get('trialEndDate') !== undefined ? subscription.get('trialEndDate').toString() : ''
      periodStart = subscription.get('periodStartDate') !== undefined ? subscription.get('periodStartDate').toString() : ''
      periodEnd = subscription.get('periodExpirationDate') !== undefined ? subscription.get('periodExpirationDate').toString() : ''
    }

    const periodEndDate = new Date(periodEnd)
    let formattedDate
    console.log('render called, variables are: planName: ' + planName + ' creationSource: ' + creationSource + ' stripeCustomerId ' + stripeCustomerId +
        ' stripeSubscriptionId: ' + stripeSubscriptionId + ' trialStart: ' + trialStart + ' trialEnd: ' + trialEnd +
        ' periodStart: ' + periodStart + ' periodEnd: ' + periodEndDate)
    console.log('periodEndDate: ' + periodEndDate)
    if (!periodEndDate) {
      formattedDate = ''
    } else {
      const dateToUse = this.state.newPeriodEndDate ? this.state.newPeriodEndDate : periodEndDate
      formattedDate = dateToUse.getFullYear() + '-' +
            ((dateToUse.getMonth() + 1) < 10 ? '0' : '') + ((dateToUse.getMonth() + 1)) + '-' +
            (dateToUse.getDate() < 10 ? '0' : '') + dateToUse.getDate()
    }

    return (
            <div className="container">
                <br/>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <h5 className="card-header">User Lookup</h5>
                            <div className="card-body">
                                <h5 className="card-title"> <b>Id:</b> <span> <a href={'https://parse-dashboard.myswimpro.com/apps/MySwimPro/browser/_User?filters=%5B%7B%22field%22%3A%22objectId%22%2C%22constraint%22%3A%22eq%22%2C%22compareTo%22%3A%22' + this.state.id + '%22%7D%5D'} target="_blank" rel="noreferrer noopener"> {this.state.id} </a> </span> </h5>
                                <div className="card-text" id="userinfo">
                                    <b>Username:</b> <span> {this.state.username} </span><br />
                                    <b>Created:</b> <span> {this.state.created} </span><br />
                                    <br />
                                    <b>Subscription:</b> <span> {this.state.subscriptionId !== undefined ? this.state.subscriptionId : undefined} </span><br />

                                    <div id="subscriptioninfo">
                                        <b>&nbsp;&nbsp;&nbsp;Plan:</b> <span> {planName} </span><br />
                                        <b>&nbsp;&nbsp;&nbsp;Elite Coach Override:</b> {eliteOverride ? 'true' : 'false'} <a href="" onClick={this.handleEliteOverrideChange}><small>Flip</small></a><br/><br/>
                                        &nbsp;&nbsp;&nbsp;<button type="button" className="btn btn-secondary" onClick={this.handleClearSubscriptionClick}>Clear Subscription Info</button><br />
                                        {this.state.clearSubscriptionStatusMessage != null ? <div><span> {this.state.clearSubscriptionStatusMessage} </span><br /></div> : <br /> }
                                        <b>&nbsp;&nbsp;&nbsp;Creation Source:</b> <span> {creationSource} </span><br />
                                        <b>&nbsp;&nbsp;&nbsp;Stripe Customer Id:</b> <span> {stripeCustomerId } </span><br />
                                        <b>&nbsp;&nbsp;&nbsp;Stripe Subscription Id:</b> <span> {stripeSubscriptionId } </span><br />
                                        <br />
                                        <b>&nbsp;&nbsp;&nbsp;Trial Start:</b> <span> {trialStart } </span><br />
                                        <b>&nbsp;&nbsp;&nbsp;Trial End:</b> <span> {trialEnd } </span><br />
                                        <br />
                                        <b>&nbsp;&nbsp;&nbsp;Period Start:</b> <span> {periodStart } </span><br />
                                        { this.state.editingPeriodEndDate
                                          ? <div><input type="date" value={formattedDate} onChange={this.onPeriodEndDateChange} /><button className="btn btn-outline-secondary" onClick={this.handleEditPeriodEndDate}>Done</button></div>
                                          : (periodEnd ? <div><b>&nbsp;&nbsp;&nbsp;Period End:</b> <span> { periodEnd } </span><button className="btn btn-outline-secondary" onClick={this.handleEditPeriodEndDate}>Edit</button><br /></div> : null)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    <div className="card-footer">
                        <form id="userlookup-form" className="form-inline">
                        <div className="form-row">
                            <div className="col">
                            <div className="form-group">
                                <input type="text" className="form-control" id="useremail" placeholder="email" onChange={this.handleEmailTextFieldChange}/>
                                <input type="text" className="form-control" id="userfirst" placeholder="first name" onChange={this.handleFirstNameTextFieldChange}/>
                                <input type="text" className="form-control" id="userlast" placeholder="last name" onChange={this.handleLastNameTextFieldChange}/>
                                <input type="text" className="form-control" id="userid" placeholder="id" onChange={this.handleIdTextFieldChange}/>
                            </div>
                            </div>
                            <div className="col">
                            <button type="button" className="btn btn-primary" onClick={this.handleButtonClick}>Lookup</button>
                            { (this.state.totalCount !== undefined) ? <b>{this.state.totalCount} users matching query</b> : null }
                            </div>
                        </div>
                        </form>
                    </div>
                    </div>

                </div>
                { (this.state.users !== undefined)
                  ? <table id="users" className="data container table-display">
                        <tbody>
                            {this.renderTableHeader()}
                            {this.renderTable()}
                        </tbody>
                    </table>
                  : null }
            </div>
    )
  }
}
