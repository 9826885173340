import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'
import {
  getStrokeString,
  formatSeconds,
  getGoalName,
  getSkillLevelString,
  convertPTI
} from './../Utils'
import DateTimePicker from 'react-datetime-picker'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import OtpInput from 'react-otp-input'
import Modal from 'react-modal'
import 'react-datepicker/dist/react-datepicker.css'
import { format, parseISO, formatISO } from 'date-fns'
import Config from './../config'

const options = [
  { value: '0', label: '1' },
  { value: '1', label: '2' },
  { value: '2', label: '3' },
  { value: '3', label: '4' },
  { value: '4', label: '5' }
]

const customStyles = {
  content: {
    top: '55%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '85%'
  }
}

export default class MemberDetail extends React.Component {
  constructor (props) {
    super(props)
    if (!Parse.User.current()) window.href = '/'
    if (this.props.match.params.id) {
      this.state = {
        member: null,
        userInfo: null,
        settings: null,
        achievements: null,
        training: null,
        dynamicIntervals: null,
        allAchievements: null,
        otp: null,
        modalIsOpen: false,
        form: {
          skillLevel: null,
          garminAccessToken: null,
          coachCallDate: '',
          onboardingCallDate: ''
        },
        subscriptionExtensions: []
      }
      this.reloadAllMemberData()
    }
    this.loadMember = this.loadMember.bind(this)
    this.loadAllAchievements()
  }

  propTypes = {
    id: PropTypes.string,
    match: {
      params: {
        id: PropTypes.string
      }
    }
  }

  reloadAllMemberData = async () => {
    this.loadMember()
    this.loadWorkouts()
    this.loadAchievements()
    this.loadDynamicIntervals()
    this.loadLibrary()
    this.getReferralStats()
    this.loadSubscriptionExtensions()
    this.loadStreaks()
  }

  loadStreaks = async () => {
    const workoutResults = await Parse.Cloud.run('getGuidedWorkoutStreakCount', { userId: this.props.match.params.id })
    this.setState({
      workoutStreak: workoutResults?.streakCountWeeks,
      workoutsThisWeek: workoutResults?.numWorkoutsCompletedThisWeek
    })
    const totdResults = await Parse.Cloud.run('getTipOfTheDayStreakCount', { userId: this.props.match.params.id })
    this.setState({
      totdStreak: totdResults?.streakCountDays
    })
  }

  loadAllAchievements = async () => {
    const query = new Parse.Query('Achievement')
    const allAchievements = await query.find()
    this.setState({
      allAchievements: allAchievements
    })
  }

  loadSubscriptionExtensions = async () => {
    const results = await Parse.Cloud.run('getSubscriptionExtensionsForUser', { userId: this.props.match.params.id })
    this.setState({
      subscriptionExtensions: results?.extensions || []
    })
  }

  loadMember = async () => {
    const id = this.props.id || this.props.match.params.id
    if (!id) {
      alert(
        'Error: No member id. URL format: https://admin.myswimpro.com/#/members/id'
      )
      return
    }

    const memberType = new Parse.Object('_User')
    const query = new Parse.Query(memberType)
    query.include([
      'userInfo_v3',
      'settings',
      'userTraining'
    ])
    const results = await query.get(id)
    if (!results) {
      alert('No member found for id ' + id)
      return
    }
    this.setState({
      member: results,
      userInfo: results.get('userInfo_v3'),
      settings: results.get('settings'),
      training: results.get('userTraining')
    })
  }

  unlinkAllAuthMethodsConfirm = async (event) => {
    event.preventDefault()
    const confirm = window.confirm('Are you sure you want to delete all auth methods? This user will need to use email/password to login.')
    if (!confirm) return
    await this.unlinkAllAuthMethods()
  }

  unlinkAllAuthMethods = async (inputUser) => {
    const user = inputUser || this.state.member
    console.log('removing auth methods for ' + user.id)
    await Parse.Cloud.run('unlinkAllAuthMethods', { userId: user.id })
    window.alert('Auth methods removed.')
  }

  loadWorkouts = () => {
    const id = this.props.id || this.props.match.params.id
    if (!id) {
      return
    }

    const user = new Parse.Object('_User')
    user.id = id

    const workoutType = new Parse.Object('CompletedWorkout_v3')
    const query = new Parse.Query(workoutType)
    query.include('workout')
    query.descending('dateCompleted')
    query.equalTo('user', user)
    query.limit(1000)
    query.find().then(
      (results) => {
        if (!results) {
          return
        }
        this.setState({
          workouts: results
        })
      },
      (error) => {
        alert('Error getting member: ' + error.message)
      }
    )
  }

  loadAchievements = () => {
    const id = this.props.id || this.props.match.params.id
    if (!id) {
      return
    }

    const achievementType = new Parse.Object('Achievement_Unlocked')
    const query = new Parse.Query(achievementType)
    query.include('achievement')
    query.descending('dateCompleted')
    query.equalTo('user_id', id)
    query.limit(1000)
    query.find().then(
      (results) => {
        if (!results) {
          return
        }
        console.log(
          '[MemberDetailComponent] Got ' + results.length + ' members.'
        )
        this.setState({
          achievements: results
        })
      },
      (error) => {
        alert('Error getting member: ' + error.message)
      }
    )
  }

  loadDynamicIntervals = () => {
    Parse.Cloud.run('loadTimes_v4', {
      userId: this.props.match.params.id,
      freestyleDistancePTI: 100
    }).then(
      (results) => {
        this.setState({
          dynamicIntervals: results
        })
      },
      (error) => {
        alert('Error getting dynamic intervals: ' + error.message)
      }
    )
  }

  handleAdTrackingChange = async (event) => {
    event.preventDefault()
    const user = this.state.member
    if (user) {
      Parse.Cloud.run('updatePrivacyPreferences', { userId: user.id }).then(
        (results) => {
          this.setState({
            member: user
          })
        },
        (error) => {
          alert('Error updating privacy status: ' + error.message)
        }
      )
    } else {
      console.log('[handleEliteOverrideChange] No user to edit')
    }
  }

  numberOfCompletedWorkoutsText = () => {
    if (!this.state.workouts) return ''
    if (this.state.workouts.length === 1000) return '1000+'
    return '' + this.state.workouts.length
  }

  handleAdminNoteEdit = (event) => {
    event.preventDefault()
    if (this.state.isEditingAdminNotes) {
      // save
      this.state.userInfo.set('adminNotes', this.state.tempAdminNotes)
      this.state.userInfo.save().then(
        () => {
          this.setState({
            isEditingAdminNotes: false
          })
        },
        (error) => {
          window.alert(error)
        }
      )
    } else {
      this.setState({
        tempAdminNotes: this.state.userInfo.get('adminNotes'),
        isEditingAdminNotes: true
      })
    }
  }

  handleAdminNoteChange = (event) => {
    this.setState({
      tempAdminNotes: event.target.value
    })
  }

  handleNewAward = async (event) => {
    event.preventDefault()
    const newAwardId = window.prompt(
      "Enter Achivement ID. You can find this on the Achievement's detail page"
    )
    if (!newAwardId || !newAwardId.length) return
    const achievement = this.state.allAchievements.find(
      (obj) => obj.id === newAwardId
    )
    if (!achievement) {
      window.alert('ERROR: Invalid Achievement ID')
      return
    }
    if (this.state.achievements.find((obj) => obj.id === newAwardId)) {
      window.alert('ERROR: Achievement already awarded.')
      return
    }

    const achivementUnlocked = new Parse.Object('Achievement_Unlocked')
    achivementUnlocked.set('user_id', this.state.member.id)
    achivementUnlocked.set('achievement', achievement)
    await achivementUnlocked.save()
    await this.loadAchievements()
  }

  handleDeleteAccount = (event) => {
    event.preventDefault()

    const confirmId = window.prompt(
      "WARNING!\n\nDeleting a member's account requires explict permission from the member who must use the email we have on file to contact us.\n\nThis action cannot be undone and deletes all member data, including workouts. To confirm, please enter the member id:",
      ''
    )

    if (!confirmId) return
    if (confirmId !== this.state.member.id) {
      window.alert('Error: Incorrect ID entered')
      return
    }

    const params = {
      objectId: this.state.member.id,
      adminOverride: true // Deprecated
    }
    Parse.Cloud.run('deleteAccount_v4', params).then(
      (results) => {
        window.location.reload(false)
      },
      (error) => {
        window.alert('Error deleting account: ' + error.message)
      }
    )
  }

  handleEditUsername = (event) => {
    event.preventDefault()

    const newEmail = window.prompt('Please enter a new email.')
    if (!newEmail || newEmail === '') return

    const params = {
      userId: this.state.member.id,
      email: newEmail
    }
    Parse.Cloud.run('updateEmail', params).then(
      (results) => {
        this.reloadAllMemberData()
      },
      (error) => {
        window.alert('Error changing email: ' + error.message)
      }
    )
  }

  handleEditFirstName = (event) => {
    event.preventDefault()

    const newValue = window.prompt('Please enter a new first name.')
    if (!newValue || newValue === '') return

    this.state.userInfo.set('firstName', newValue)
    this.state.userInfo.save().then(
      () => {
        this.setState({
          userInfo: this.state.userInfo
        })
      },
      (error) => {
        window.alert('Error saving: ' + error.message)
      }
    )
  }

  handleEditLastName = (event) => {
    event.preventDefault()

    const newValue = window.prompt('Please enter a new last name.')
    if (!newValue || newValue === '') return

    this.state.userInfo.set('lastName', newValue)
    this.state.userInfo.save().then(
      () => {
        this.setState({
          userInfo: this.state.userInfo
        })
      },
      (error) => {
        window.alert('Error saving: ' + error.message)
      }
    )
  }

  handleEditTotalTime = (event) => {
    event.preventDefault()

    const newValue = window.prompt('Please enter a new total time, in minutes.')
    if (!newValue || newValue === '') return
    const newNumber = parseInt(newValue)

    this.state.userInfo.set('totalTime', newNumber * 60)
    this.state.userInfo.save().then(
      () => {
        this.setState({
          userInfo: this.state.userInfo
        })
      },
      (error) => {
        window.alert('Error saving: ' + error.message)
      }
    )
  }

  handleEditTotalDistance = (event) => {
    event.preventDefault()

    const newValue = window.prompt(
      'Please enter a new total distance, in meters.'
    )
    if (!newValue || newValue === '') return
    const newNumber = parseInt(newValue)

    this.state.userInfo.set('totalDistance', newNumber)
    this.state.userInfo.save().then(
      () => {
        this.setState({
          userInfo: this.state.userInfo
        })
      },
      (error) => {
        window.alert('Error saving: ' + error.message)
      }
    )
  }

  handleEditTotalWorkouts = (event) => {
    event.preventDefault()

    const newValue = window.prompt(
      'Please enter a new total number of workouts.'
    )
    if (!newValue || newValue === '') return
    const newNumber = parseInt(newValue)

    this.state.userInfo.set('totalWorkoutsCompleted', newNumber)
    this.state.userInfo.save().then(
      () => {
        this.setState({
          userInfo: this.state.userInfo
        })
      },
      (error) => {
        window.alert('Error saving: ' + error.message)
      }
    )
  }

  renderAccount = () => {
    return (
      <div>
        <b>Username</b>{' '}
        <small>
          <a href="" onClick={this.handleEditUsername}>
            Edit
          </a>
        </small>
        <p>
          <span className="bg-light" style={{ 'white-space': 'pre' }}>
            {this.state.member.get('username')}
          </span>
        </p>
        <b>Account Created</b>
        <p>{this.state.member.get('createdAt').toString()}</p>
        <div className="row">
          <div className="col-sm-4">
            <b>Garmin</b>
            <p>
              {this.state.member.get('garminAccessToken') ? 'authorized' : 'not linked'}
            </p>
          </div>
          <div className="col-sm-4">
            <b>Strava</b>
            <p>
              {this.state.member.get('stravaAccessToken') ? 'authorized' : 'not linked'}
            </p>
          </div>
          <div className="col-sm-4">
            <b>TrainingPeaks</b>
            <p>
              {this.state.member.get('trainingPeaksAccessToken') ? 'authorized' : 'not linked'}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <b>Transactional Emails</b>
            <p>
              {this.state.member.get('isTransactionalEmailsUnsubscribed')
                ? 'Unsubscribed'
                : 'Allowed'}
            </p>
          </div>
          <div className="col">
            <b>
              Ad Tracking{' '}
              <a href="" onClick={this.handleAdTrackingChange}>
                <small>Flip</small>
              </a>
            </b>
            <p>
              {this.state.member.get('isAdTrackingDisabled') === undefined
                ? 'Not set'
                : this.state.member.get('isAdTrackingDisabled')
                  ? 'Opted-out'
                  : 'Allowed'}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <b>Last Password Reset Link</b>
            <p>
              {this.state.member.get('lastPasswordResetLink')}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <b>Auth Accounts</b>
            <p>
              <button className="btn btn-sm btn-danger" onClick={this.unlinkAllAuthMethodsConfirm}>
                Unlink All Auth Accounts
              </button>
            </p>
          </div>
        </div>
      </div>
    )
  }

  renderSettings = () => {
    if (!this.state.settings) return <i>No Settings</i>

    return (
      <div>
        <div className="row">
          <div className="col-sm-6">
            <b>Pools</b>
            <div>
              <ul>
                {(this.state.member.get('pools') || []).map((pool) => {
                  if (pool.isDisplayInYards) {
                    const convertedDistanceRaw = pool.distanceInMeters * 1.09361
                    const convertedDistanceRoundTo2decimals = Math.round(convertedDistanceRaw * 100) / 100
                    return <li>{convertedDistanceRoundTo2decimals}yd {pool.isDefault ? ' (default)' : ''}</li>
                  } else {
                    return <li>{pool.distanceInMeters}m {pool.isDefault ? ' (default)' : ''}</li>
                  }
                })}
              </ul>
            </div>
          </div>
          <div className="col-sm-6 text-muted">
            <b>Preferred Pools (deprecated)</b>
            <div>
              <ul>
                {(this.state.member.get('preferredPools') || []).map((pool) => {
                  return <li>{pool.length}{pool.unit === 0 ? 'm' : 'yd'}{pool.isDefault ? ' (default)' : ''}</li>
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <b>Notifications</b>
            <p>{this.state.settings.get('isNotificationOn') ? 'On' : 'Off'}</p>
          </div>
          <div className="col-sm-6">
            <b>Private</b>
            <p>{this.state.settings.get('isPrivate') ? 'true' : 'false'}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <b>Strength Level</b>
            <p>{this.state.settings.get('strengthLevel') || '-'}</p>
          </div>
          <div className="col-sm-6">
            <b>Has Equipment</b>
            <p>{this.state.member.get('hasEquipment') ? 'true' : 'false'}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <b>Swim Goals</b>
            <div>
              <ul>
                {(this.state.settings.get('goalsSwim') || []).map((goal) => {
                  return <li key={goal.id}>{getGoalName(goal)}</li>
                })}
              </ul>
            </div>
          </div>
          <div className="col-sm-6">
            <b>Dryland Goals</b>
            <div>
              <ul>
                {(this.state.settings.get('goalsDryland') || []).map((goal) => {
                  return <li key={goal.id}>{getGoalName(goal)}</li>
                })}
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <b>Strokes selected</b>
            <div>
              <ul>
                {(this.state.settings.get('swimStrokes') || []).map((isStrokeEnabled, index) => {
                  return <li key={index}>{getStrokeString(index) + ': '}<b>{(isStrokeEnabled ? '✅' : 'X')}</b></li>
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }

  getReferralStats = () => {
    const id = this.props.id || this.props.match.params.id
    Parse.Cloud.run('getUserReferralCode', { userId: id }).then(
      (results) => {
        this.setState({
          referralCode: results.code
        })
      },
      (error) => {
        console.error('Error getting referral code: ' + error.message)
      }
    )
    Parse.Cloud.run('getCouponCodeRedemptionCount', { userId: id }).then(
      (results) => {
        this.setState({
          referralCount: results.count || 0
        })
      },
      (error) => {
        console.error('Error getting referral code: ' + error.message)
      }
    )
  }

  readFitFile = (event) => {
    const file = event.target.files[0]

    if (file) {
      const activityId = file.name.slice(0, file.name.indexOf('.'))
      this.setState({
        isLoggingFitFile: true
      })
      const reader = new FileReader()
      reader.onload = (e) => {
        console.log(
          '[readFitFile] Logging activity ID ' +
            activityId +
            ' for ' +
            this.state.member.id +
            '...'
        )
        const base64 = e.target.result.split(',')[1]
        const params = {
          data: base64,
          userId: this.state.member.id,
          activityId: activityId
        }
        Parse.Cloud.run('fitFileUpload', params).then(
          (results) => {
            window.location.reload(false)
          },
          (error) => {
            window.alert('Error logging fit file: ' + error.message)
          }
        )
      }
      reader.onerror = (e) => {
        // error occurred
        console.log('[readFitFile] Error : ' + e.type)
        window.alert('Error getting file: ' + e.message)
      }
      reader.readAsDataURL(file)
    }
  }

  renderAchievements = () => {
    if (!this.state.achievements) return 'None'
    return (
      <ul>
        {this.state.achievements.map((obj) => {
          return (
            <li key={obj.id}>
              {obj.get('achievement').get('name')}{' '}
              <small className="text-muted">
                {obj.get('dateCompleted')
                  ? obj.get('dateCompleted').toString()
                  : ''}
              </small>
            </li>
          )
        })}
      </ul>
    )
  }

  renderDynamicIntervals = () => {
    if (!this.state.dynamicIntervals) return 'None'
    return (
      <table>
        <tbody>
          {this.state.dynamicIntervals.map((obj) => {
            return (
              <tr key={obj.id}>
                <td>
                  {obj.distance} {obj.poolCourse === 0 ? 'yd' : 'm'} {getStrokeString(obj.stroke)}{' '}
                </td>
                <td>
                  &nbsp;db:
                  <code>{' '}{formatSeconds(obj.time)}</code>
                </td>
                <td>
                  &nbsp;app:
                  <code>{' '}{this.getPTITimeString(obj)}</code>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  getPTITimeString = (obj) => {
    const isTimeYards = obj.poolCourse === 0
    const isDisplayYards = this.getDefaultPool()?.isDisplayInYards === true
    const time = convertPTI(obj.time, !isTimeYards, !isDisplayYards)
    return formatSeconds(time)
  }

  getDefaultPool = () => {
    const pool = this.state.member.get('pools')?.find((element) => { return element.isDefault })
    return pool
  }

  getHTMLSafeAdminNotes = () => {
    const notes = this.state.userInfo.get('adminNotes')
    if (!notes) return '-'
    return notes.split('\n').map((value, index) => {
      return (
        <span key={index}>
          {value}
          <br />
        </span>
      )
    })
  }

  renderInfo = () => {
    if (!this.state.userInfo) return <i>No Info</i>

    return (
      <div>
        <div className="row">
          <div className="col-sm-6">
            <b>First Name</b>{' '}
            <small>
              <a href="" onClick={this.handleEditFirstName}>
                Edit
              </a>
            </small>
            <p>{this.state.userInfo.get('firstName') || '-'}</p>
          </div>
          <div className="col-sm-6">
            <b>Last Name</b>{' '}
            <small>
              <a href="" onClick={this.handleEditLastName}>
                Edit
              </a>
            </small>
            <p>{this.state.userInfo.get('lastName') || '-'}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <b>Total Time</b>{' '}
            <small>
              <a href="" onClick={this.handleEditTotalTime}>
                Edit
              </a>
            </small>
            <p>
              {Math.round((this.state.userInfo.get('totalTime') || 0) / 60)}{' '}
              minutes
            </p>
          </div>
          <div className="col-sm-6">
            <b>Total Distance</b>{' '}
            <small>
              <a href="" onClick={this.handleEditTotalDistance}>
                Edit
              </a>
            </small>
            <p>{this.state.userInfo.get('totalDistance') || 0} meters</p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <b>Total Workouts</b>{' '}
            <small>
              <a href="" onClick={this.handleEditTotalWorkouts}>
                Edit
              </a>
            </small>
            <p>{this.state.userInfo.get('totalWorkoutsCompleted')} workouts</p>
          </div>
          <div className="col-sm-6">
            <b>Longest Streak</b>
            <p>{this.state.userInfo.get('longestStreak')} workouts</p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <b>Current Streak (static)</b>{' '}
            <p>{this.state.userInfo.get('workoutStreakWeeks')} weeks</p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <b>Avg Fr SWOLF v3</b>{' '}
            <p>{this.state.userInfo.get('swolfFreestyleSum_v3') / this.state.userInfo.get('swolfFreestyleCount_v3')}</p>
          </div>
          <div className="col-sm">
            <b>Avg Fr SWOLF v4</b>{' '}
            <p>{this.state.userInfo.get('swolfFreestyleSum_v4') / this.state.userInfo.get('swolfFreestyleCount_v4')}</p>
          </div>
          <div className="col-sm">
            <b>Avg Fr SWOLF v5</b>{' '}
            <p>{this.state.userInfo.get('swolfFreestyleSum_v5') / this.state.userInfo.get('swolfFreestyleCount_v5')}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <b>Followers</b>
            <p>{(this.state.userInfo.get('followers') || []).length}</p>
          </div>
          <div className="col-sm-6">
            <b>Following</b>
            <p>{(this.state.userInfo.get('following') || []).length}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <b>Referral Code</b>
            <p>
              <a href={'https://myswimpro.com/refer?code=' + (this.state.referralCode || 'referral')}>
                {this.state.referralCode || 'n/a'}
              </a>
            </p>
          </div>
          <div className="col-sm-6">
            <b>Referrals</b>
            <p>{'' + (this.state.referralCount || 0)}</p>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <b className="card-title">Admin Notes</b>{' '}
            <small>
              <a href="" onClick={this.handleAdminNoteEdit}>
                {this.state.isEditingAdminNotes ? 'Save' : 'Edit'}
              </a>
            </small>
            <p className="card-text">
              {this.state.isEditingAdminNotes
                ? (
                <textarea
                  value={this.state.tempAdminNotes}
                  onChange={this.handleAdminNoteChange}
                />
                  )
                : (
                    this.getHTMLSafeAdminNotes() || '-'
                  )}
            </p>
          </div>
        </div>
        <br />
        <b>Personalized Training Intervals</b>
        {this.renderDynamicIntervals()}
        <span>
          <b>Achievements</b>{' '}
          <button className="btn btn-link btn-sm" onClick={this.handleNewAward}>
            Award New
          </button>
        </span>
        <div>
          {this.renderAchievements()}
        </div>
      </div>
    )
  }

  renderActivePlans = () => {
    if (!this.state.training || !this.state.training.get('activePlans')) {
      return <i>None</i>
    }
    return (
      <ul>
        {Object.entries(this.state.training.get('activePlans')).map((obj) => {
          return (
            <li key={obj[0]}>
              <a href={'/#/trainingplans/' + obj[0]}>{obj[0]}</a> :{' '}
              <a href={'/#/trainingplans/' + obj[1]}>{obj[1]}</a>
            </li>
          )
        })}
      </ul>
    )
  }

  renderTraining = () => {
    if (!this.state.training) return <i>No Training</i>

    return (
      <div>
        <div className="row">
          <div className="col-sm-6">
            <b>Active Dryland Plan</b>
            <p>
              <a
                href={
                  '/#/trainingplans/' +
                  this.state.training.get('activeStrengthPlanId')
                }
              >
                {this.state.training.get('activeStrengthPlanId') || '-'}
              </a>
            </p>
          </div>
          <div className="col-sm-6">
            <b>Active Swim Plan</b>
            <p>
              <a
                href={
                  '/#/trainingplans/' +
                  this.state.training.get('activeSwimPlanId')
                }
              >
                {this.state.training.get('activeSwimPlanId') || '-'}
              </a>
            </p>
          </div>
        </div>
        <b>All Active Plans</b>
        <div>
          {this.renderActivePlans()}
        </div>
      </div>
    )
  }

  renderEditMember = () => {
    return (
      <div>
        <div className="row">
          <div className="col-sm-6">
            <b>Skill Level</b>
            <p>
              <small>
                {(this.state.member && this.state.member.get('skillLevel')) ||
                this.state.member.get('skillLevel') == 0
                  ? getSkillLevelString(this.state.member.get('skillLevel'))
                  : '-'}
              </small>
            </p>
          </div>
          <div className="col-sm-6">
            <b>Garmin sync token </b>
            <p>
              <small>{this.state.member.get('garminAccessToken') ? this.state.member.get('garminAccessToken').substring(0, 4) : '-'}</small>
            </p>
          </div>

          <div className="col-sm-6">
            <b>Onboarding Call </b>
            <p>
              <small>
                {(this.state.member.get('onboardingCallDate') &&
                  format(
                    parseISO(this.state.member.get('onboardingCallDate')),
                    'dd/MM/yyyy'
                  )) ||
                  '-'}
              </small>
            </p>
          </div>

          <div className="col-sm-6">
            <b>Coach Call</b>
            <p>
              <small>
                {(this.state.member.get('coachCallDate') &&
                  format(
                    parseISO(this.state.member.get('coachCallDate')),
                    'dd/MM/yyyy'
                  )) ||
                  '-'}
              </small>
            </p>
          </div>
        </div>
      </div>
    )
  }

  handleChange = (otp) => {
    const updates = this.state.form
    updates.garminAccessToken = otp
    this.setState({
      form: updates
    })
  }

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      form: {
        skillLevel: null,
        garminAccessToken: null,
        coach_call: '',
        onboarding_call: ''
      }
    })
  }

  loadLibrary = async () => {
    const id = this.props.id || this.props.match.params.id
    if (!id) {
      return
    }
    const user = new Parse.User()
    user.id = id
    const query = new Parse.Query('UserSavedWorkout')
    query.equalTo('user', user)
    query.include('workout')
    query.limit(1000)
    const lib = await query.find()
    this.setState({
      memberLibrary: lib
    })
  }

  renderLibrary = () => {
    if (!this.state.memberLibrary) return <div>Loading...</div>

    return (
      <div>
        <ul>
          {this.state.memberLibrary.map((obj) => {
            if (!obj.get('workout')) return <li>⚠️ Error { obj.id }</li>
            return (
              <li key={obj.id}>
                <a href={'/#/workouts/' + obj.get('workout').id}>
                  {obj.get('workout').get('title') || obj.id}
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  renderWorkouts = () => {
    if (!this.state.workouts) return <i>Loading...</i>
    if (this.state.workouts.length === 0) return <i>No workouts</i>

    return (
      <table className="data">
        <tbody>
          <tr key={'0-header'} className="data">
            <th className="bg-primary" key="0h">
              Name
            </th>
            <th className="bg-primary" key="1h">
              Duration
            </th>
            <th className="bg-primary" key="2h">
              Distance
            </th>
            <th className="bg-primary" key="3h">
              Date
            </th>
            <th className="bg-primary" key="4h">
              Type
            </th>
            <th className="bg-primary" key="5h">
              Input .fit
            </th>
            <th className="bg-primary" key="6h">
              Output .fit
            </th>
          </tr>
          {this.state.workouts.map((completedWorkout, index) => {
            const workout = completedWorkout.get('workout')
            return (
              <tr key={index} className="data">
                <td>
                  <a href={'/#/completedWorkouts/' + completedWorkout.id}>
                    <b>{completedWorkout.get('title')}</b><br/>
                    { completedWorkout.get('heartRateData') && completedWorkout.get('heartRateData').length ? <span title="HR samples">❤️</span> : '' }
                    { completedWorkout.get('lapSamplesData') && completedWorkout.get('lapSamplesData').length ? <span title="Lap samples">⏱️</span> : '' }
                    { completedWorkout.get('locationSamples') && completedWorkout.get('locationSamples').length ? <span title="GPS samples">🗺</span> : '' }
                    { completedWorkout.get('workout') && completedWorkout.get('workout').get('isTestSetWorkout') ? <span title="Test Set">🏁</span> : '' }
                    { completedWorkout.get('isGenerated') ? <span title="Generated workout">🪄</span> : '' }
                    { completedWorkout.get('garminConnectWorkoutId') && completedWorkout.get('garminConnectWorkoutId').length ? <span title="Garmin Connect workout">📲</span> : '' }
                    { completedWorkout.get('isSplashModeRunner') ? <span title="Splash Mode">💦</span> : '' }
                    { completedWorkout.get('image') ? <span title="Photo">📸</span> : '' }
                  </a>
                </td>
                <td>
                  {Math.round((completedWorkout.get('totalTime') || 0) / 60)}{' '}
                  min
                </td>
                <td>
                  {workout
                    ? workout.get('isAllStrength')
                      ? 'N/A'
                      : completedWorkout.get('totalDistance')
                    : '?'}
                </td>
                <td>{completedWorkout.get('dateCompleted').toString()}</td>
                <td>
                  {workout
                    ? workout.get('isAllStrength')
                      ? 'Dryland'
                      : 'Swim'
                    : '?'}
                    <br/>
                    <span className="text-muted">{ completedWorkout.get('isGuided') ? 'Guided' : '' }</span>
                </td>
                <td>
                  {completedWorkout.get('sourceFITFile')
                    ? (
                    <a
                      href={completedWorkout.get('sourceFITFile').url()}
                      download
                    >
                      <button className="btn btn-sm btn-secondary">
                        Download
                      </button>
                    </a>
                      )
                    : (
                        ''
                      )}
                </td>
                <td>
                  {completedWorkout.get('createdFITFile')
                    ? (
                    <a
                      href={completedWorkout.get('createdFITFile').url()}
                      download
                    >
                      <button className="btn btn-sm btn-secondary">
                        Download
                      </button>
                    </a>
                      )
                    : (
                        ''
                      )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  setMemberInfo = async (e) => {
    e.preventDefault()

    const form = this.state.form

    const user = {
      objectId: this.state.member.id,
      garminAccessToken: form.garminAccessToken,
      coachCallDate: formatISO(form.coachCallDate),
      onboardingCallDate: formatISO(form.onboardingCallDate),
      skillLevel: parseInt(form.skillLevel)
    }

    Parse.Cloud.run('updateUser', { user: user }).then(
      (results) => {
        this.reloadAllMemberData()
      },
      (error) => {
        window.alert('Error setting member info: ' + error.message)
      }
    )

    this.setState({
      modalIsOpen: false,
      form: {
        skillLevel: null,
        garminAccessToken: null,
        coach_call: '',
        onboarding_call: ''
      }
    })
  }

  extendSubscriptionProd = async (e) => {
    e?.preventDefault()
    await this.extendSubscription(false)
  }

  extendSubscriptionSandbox = async (e) => {
    e?.preventDefault()
    await this.extendSubscription(true)
  }

  extendSubscription = async (isSandbox) => {
    const numDaysText = prompt('How many days (max 90)?')
    if (!numDaysText) return
    const numDays = parseInt(numDaysText)
    if (isNaN(numDays)) {
      alert('Invalid number of days')
      return
    }

    const body = {
      userId: this.state.member.id,
      extensionDays: numDays,
      isSandbox
    }

    Parse.Cloud.run('extendSubscription', body).then(
      (results) => {
        this.reloadAllMemberData()
      },
      (error) => {
        window.alert('Error extending subscription: ' + error.message)
      }
    )
  }

  render = () => {
    if (!this.state.member) return <div>Loading...</div>
    return (
      <div className="container">
        <br />
        <div className="row align-content-center">
          <div className="col">
            <h1>
              {this.state.userInfo
                ? (this.state.userInfo.get('firstName') || '-') +
                  ' ' +
                  (this.state.userInfo.get('lastName') || '-')
                : '- -'}
            </h1>
          </div>
          <div className="col">
            <a href={ `/#/workouts/new?generateWorkoutUserId=${this.state.member.id}` }>
              <button className="btn btn-sm btn-secondary float-right">
                { this.state.isGeneratingWorkout ? 'Loading...' : '🧪 Generate Workout' }
              </button>
            </a>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-sm-6">
            <div className="card">
              <h5 className="card-header">
                Account &nbsp;
                <small className="text-muted">{this.state.member.id}</small>
              </h5>
              <div className="card-body">{this.renderAccount()}</div>
            </div>
            <br />
            <div className="card">
              <h5 className="card-header">
                Info &nbsp;
                <small className="text-muted">
                  {this.state.userInfo ? this.state.userInfo.id : ''}
                </small>
              </h5>
              <div className="card-body">{this.renderInfo()}</div>
            </div>
            <br />
            <div className="card">
              <h5 className="card-header">
                Library Workouts &nbsp;
                <small className="text-muted">
                  {this.state.memberLibrary
                    ? this.state.memberLibrary.length
                    : ''}
                </small>
              </h5>
              <div className="card-body">{this.renderLibrary()}</div>
            </div>
            <br />
          </div>
          <div className="col-sm-6">
            <div className="card">
              <h5 className="card-header">
                Subscription
              </h5>
              <div className="card-body">
                <button className="btn btn-sm btn-outline-primary" onClick={this.extendSubscriptionProd}>
                  Extend Subscription
                </button>
                <br/><br/>
                <button className="btn btn-sm btn-outline-secondary" onClick={this.extendSubscriptionSandbox}>
                  Extend Subscription (Sandbox)
                </button>
                <br/><br/>
                <b>Subscription Extension History:</b>
                {this.state.subscriptionExtensions.length === 0 ? <i> None</i> : ''}
                <ol>
                  {
                    this.state.subscriptionExtensions.map((extension) => {
                      return <li key={extension.objectId}><b>+{extension.days} Days ({extension.platform})</b>: {(new Date(extension.createdAt)).toString()}</li>
                    })
                  }
                </ol>
              </div>
              <div className="card-footer">
                <a href={'https://app.revenuecat.com/customers/' + Config.revenueCatProjectId + '/' + this.state.member.id} target='_blank' rel='noreferrer'>
                  <button className="btn btn-sm btn-primary">
                    View Subscription History on RevenueCat
                  </button>
                </a>
              </div>
            </div>
            <br />
            <div className="card">
              <h5 className="card-header">
                Settings &nbsp;
                <small className="text-muted">
                  {this.state.settings ? this.state.settings.id : ''}
                </small>
              </h5>
              <div className="card-body">{this.renderSettings()}</div>
            </div>
            <br />

            <div className="card">
              <h5 className="card-header">
                Streaks
              </h5>
              <div className="card-body">
                <div className="row">
                  <div className="col-6">
                    <b>Workout</b>
                    <p>{'' + (this.state.workoutStreak || 0)} weeks ({this.state.workoutsThisWeek || 0} this week)</p>
                  </div>
                  <div className="col-6">
                    <b>Tip of the Day</b>
                    <p>{'' + (this.state.totdStreak || 0)} days</p>
                  </div>
                </div>
              </div>
            </div>
            <br/>

            <div className="card">
              <h5 className="card-header">
                Training &nbsp;
                <small className="text-muted">
                  {this.state.training ? this.state.training.id : ''}
                </small>
              </h5>
              <div className="card-body">{this.renderTraining()}</div>
            </div>

            <div className="card mt-4">
              <h5 className="card-header">
                Member-Info &nbsp;
                <small
                  className="text-danger"
                  onClick={() => {
                    this.setState({
                      form: {
                        skillLevel: this.state.member.get('skillLevel'),
                        garminAccessToken:
                          this.state.member.get('garminAccessToken') || null,
                        coachCallDate:
                          (this.state.member.get('coachCallDate') &&
                            new Date(this.state.member.get('coachCallDate'))) ||
                          new Date(),
                        onboardingCallDate:
                          (this.state.member.get('onboardingCallDate') &&
                            new Date(
                              this.state.member.get('onboardingCallDate')
                            )) ||
                          new Date()
                      },
                      modalIsOpen: true
                    })
                  }}
                >
                  Edit
                </small>
              </h5>
              <div className="card-body">{this.renderEditMember()}</div>
            </div>

            <br />
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="row justify-content-between">
          <div className="col">
            <h4>
              Completed Activities &nbsp;
              <small className="text-muted">
                {this.numberOfCompletedWorkoutsText()}
              </small>
            </h4>
          </div>
          <div className="col">
            <div className="form-group text-right">
              <label>
                <b>
                  {this.state.isLoggingFitFile
                    ? 'Logging...'
                    : 'Log from fit file:'}
                </b>
                &nbsp;
              </label>
              <input
                disabled={this.state.isLoggingFitFile}
                type="file"
                onChange={(event) => {
                  this.readFitFile(event)
                }}
                id="file_upload"
              />
            </div>
          </div>
        </div>

        {this.renderWorkouts()}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <button
          className="btn btn-danger btn-sm pull-right"
          onClick={this.handleDeleteAccount}
        >
          Delete Account
        </button>

        <br />
        <br />

        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <form onSubmit={this.setMemberInfo}>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Skill Level </label>

              <select
                className="custom-select"
                value={this.state.form.skillLevel}
                name="skillLevel"
                onChange={(event) => {
                  const updates = this.state.form
                  updates.skillLevel = event.target.value
                  this.setState({
                    form: updates
                  })
                }}
              >
                <options value="">Select SkillLevel</options>
                {options.map((item) => {
                  return <option value={item.value}>{item.label}</option>
                })}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Garmin Sync Token </label>
              <OtpInput
                value={this.state.form.garminAccessToken}
                onChange={this.handleChange}
                numInputs={4}
                separator={<span className="ml-1 mr-1">-</span>}
                inputStyle={{ width: 60 }}
              />
            </div>

            <div className=" row mt-2">
              <label htmlFor=" col-sm-12 ml-2" style={{ paddingLeft: 16 }}>
                Onboarding Call{' '}
              </label>
              <div className="col-sm-12">
                <DatePicker
                  showMonthDropdown
                  showYearDropdown
                  id="onboarding_call"
                  name="onboarding_call"
                  dateFormat="dd/MM/yyyy"
                  title="Click to select a Coach Call"
                  placeholderText="Click to select a Coach Call "
                  className="form-control"
                  onChange={(date) => {
                    const updates = this.state.form
                    updates.onboardingCallDate = date
                    this.setState({
                      form: updates
                    })
                  }}
                  selected={this.state.form.onboardingCallDate}
                />
              </div>
            </div>

            <div className=" row mt-2">
              <label htmlFor=" col-sm-12 ml-2" style={{ paddingLeft: 16 }}>
                Coach Call{' '}
              </label>
              <div className="col-sm-12">
                <DatePicker
                  showMonthDropdown
                  showYearDropdown
                  id="coach_call"
                  name="coach_call"
                  dateFormat="dd/MM/yyyy"
                  title="Click to select a Coach Call"
                  placeholderText="Click to select a Coach Call "
                  className="form-control"
                  onChange={(date) => {
                    const updates = this.state.form
                    updates.coachCallDate = date
                    this.setState({
                      form: updates
                    })
                  }}
                  selected={this.state.form.coachCallDate}
                />
              </div>
            </div>

            <button type="submit " className="btn btn-primary mt-4">
              Save & Update
            </button>
          </form>
        </Modal>
      </div>
    )
  }
}
